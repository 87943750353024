import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles, Divider } from '@material-ui/core'
import AboveTitle from '../mainComponents/aboveTitle'
import Title from '../layout/footer/title'
import Table from './table'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 80,
        marginBottom: 40,
        '@media (max-width: 1024px)': {
            marginBottom: '6vw',
        },
        '@media (max-width: 767px)': {
            marginBottom: '10vw',
        },
    },
    container: {
        maxWidth: theme.palette.width.limit,
        width: 'calc(100% - 60px)',
        backgroundColor: theme.palette.background.bigCard,
    },
    wrapper: {
        padding: 20,
    },
    title: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& [class^="makeStyles-root"]': {
            marginBottom: 0,
        },
    },
    divider: {
        width: '100%',
        background: 'white',
        opacity: .6,
        marginTop: 30,
        marginBottom: 30,
    },
    equipment: {
        '& [class^="makeStyles-divider"]': {
            '&:last-child': {
                marginBottom: 0,
            },
        },
    },
    brand: {
        width: 200,
        '@media (max-width: 1024px)': {
            width: 170,
        },
        '@media (max-width: 767px)': {
            marginBottom: 20,
        },
    },
    img: {
        width: '100%',
        height: 'auto',
    },
    models: {
        width: 320,
        '& .addition': {
            marginBottom: 10,
        },
        '@media (max-width: 1024px)': {
            width: '20vw',
        },
        '@media (max-width: 767px)': {
            width: '38vw',
        },
        '& [class^="MuiTypography-root"]': {
            '@media (max-width: 348px)': {
                fontSize: '3vw',
            },
        },
    },
    batteries: {
        width: 150,
        '& .addition': {
            marginBottom: 10,
        },
        '& [class^="MuiTypography-root"]': {
            '@media (max-width: 348px)': {
                fontSize: '3vw',
            },
        },
        '@media (max-width: 1024px)': {
            width: '10vw',
        },
        '@media (max-width: 767px)': {
            width: '38vw',
        },
        '& ul': {
            columns: '1',
            WebkitColumns: '1',
            MozColumns: '1',
        },
    },
    wrapperSupport: {
        padding: '0px 100px',
        '@media (max-width: 1424px)': {
            padding: '0vw 6.9vw',
        },
        '@media (max-width: 1024px)': {
            padding: '0vw 2vw',
        },
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (max-width: 767px)': {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
    },
    wrapperModels: {
        width: 670,
        display: 'flex',
        justifyContent: 'space-between',
        '@media (max-width: 1424px)': {
            width: '46vw',
        },
        '@media (max-width: 1024px)': {
            width: '45vw',
        },
        '@media (max-width: 767px)': {
            width: '100%',
        },
    },
    allSupport: {
        '&:last-child': {
            '& [class^="MuiDivider-root"]': {
                marginBottom: 0,
            },
        },
    },
}))

export default function Supported() {

    const classes = useStyles()

    const data = useStaticQuery(graphql`
    query Support {
        allPrismicSupportedEquipment {
          edges {
            node {
              data {
                title {
                  text
                }
              }
            }
          }
        }
        allPrismicSupportedEquipmentBodySupportedEquipment(
            sort: {fields: primary___index_number}
        ) {
          edges {
            node {
              primary {
                batteries {
                  raw
                }
                models {
                  raw
                }
                img {
                  localFile {
                    childImageSharp {
                      fluid {
                        src
                        srcWebp
                      }
                    }
                  }
                  alt
                }
              }
            }
          }
        }
      }
      
    `)

    const IsMobile = typeof window !== 'undefined' && window.matchMedia("(max-width: 767px)").matches
    const IsPad = typeof window !== 'undefined' && window.matchMedia("(max-width: 1024px)").matches

    const allData = data.allPrismicSupportedEquipmentBodySupportedEquipment.edges

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <div className={classes.wrapper}>
                    <div className={classes.title}>
                        <AboveTitle number={'06'} title={data.allPrismicSupportedEquipment.edges[0].node.data.title.text} />
                    </div>
                    <Divider orientation="horizontal" className={classes.divider} />
                    <div className={classes.equipment}>
                        {allData.map((support, i) => (
                            <div className={classes.allSupport} key={"support" + i}>
                                <div className={classes.support}>
                                    <div className={classes.wrapperSupport}>
                                        <div className={classes.brand}>
                                            <picture>
                                                <sourse
                                                    className={classes.img}
                                                    width="200" height="151"
                                                    srcSet={support.node.primary.img.localFile.childImageSharp.fluid.srcWebp}
                                                    type="image/webp"/>
                                                <img
                                                    className={classes.img}
                                                    width="200" height="151"
                                                    src={support.node.primary.img.localFile.childImageSharp.fluid.src}
                                                    alt={support.node.primary.img.alt} />
                                            </picture>
                                        </div>
                                        <div className={classes.wrapperModels}>
                                            <div className={classes.models}>
                                                <Title name={'models'} />
                                                <Table data={support.node.primary.models} realLength={8} gradient={(IsPad === true) ? 205 : 320} />
                                            </div>
                                            <div className={classes.batteries}>
                                                <Title name={'batteries'} />
                                                <Table data={support.node.primary.batteries} realLength={(IsMobile === true) ? 8 : [(IsPad === true) ? 5 : 4]} gradient={(IsPad === true) ? 103 : 150} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Divider orientation="horizontal" className={classes.divider} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}