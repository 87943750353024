import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../mainComponents/button";
import addToMailchimp from "gatsby-plugin-mailchimp";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    "@media (max-width: 1024px)": {},
    "@media (max-width: 767px)": {},
  },
  input: {
    width: 690,
    height: 42,
    border: "1px solid #FFFFFF",
    outline: "none",
    borderRadius: "1000px",
    background: "transparent",
    color: theme.palette.primary.main,
    fontSize: 14,
    padding: "0px 30px",
    fontFamily: "Krona One",
    textTransform: "none",
    lineHeight: "100%",
    "@media (max-width: 1439px)": {
      width: "calc(45vw - 30px)",
      height: 42,
    },
    "@media (max-width: 1024px)": {
      fontSize: "1.4vw",
      width: "calc(45vw - 30px)",
      height: "5vw",
    },
    "@media (max-width: 767px)": {
      fontSize: "2.8vw",
      paddingLeft: "4vw",
      paddingRight: "4vw",
      width: "calc(80vw - 4vw)",
      height: "8vw",
    },
  },
  textarea: {
    marginTop: 15,
    width: 690,
    height: 113,
    border: "1px solid #FFFFFF",
    outline: "none",
    borderRadius: "32.5px",
    padding: "20px 30px",
    background: "transparent",
    color: theme.palette.primary.main,
    fontSize: 14,
    fontFamily: "Krona One",
    textTransform: "none",
    lineHeight: "130%",
    "@media (max-width: 1439px)": {
      width: "calc(45vw - 30px)",
    },
    "@media (max-width: 1024px)": {
      fontSize: "1.4vw",
      width: "calc(45vw - 30px)",
      height: "8.5vw",
      borderRadius: "26px",
    },
    "@media (max-width: 767px)": {
      fontSize: "2.8vw",
      paddingLeft: "4vw",
      paddingRight: "4vw",
      width: "calc(80vw - 4vw)",
      height: "20vw",
      borderRadius: "26px",
    },
  },
  button: {
    marginTop: 30,
    "@media (max-width: 767px)": {
      marginTop: "5vw",
    },
  },
  link: {
    textDecoration: 'none',
  },
}));

export default function Form() {
  const [email, setEmail] = useState("");
  const [device, setDevice] = useState("");
  async function handleSubmit(e) {
    // e.preventDefault();
    const result = await addToMailchimp(email, { DEVICE: device });
    //alert with mailchimp response
    alert(result.msg);
  }

  const classes = useStyles();
  return (
    <div>
      <div>
        <a href={'https://forms.gle/aaZVqNM84D5VWgv46'} target="_blank" rel="noopener noreferrer" className={classes.link}>
          <Button>Add my device</Button>
        </a>
      </div>
      {/* <form className={classes.root} onSubmit={handleSubmit}>
        <input
          id="email"
          placeholder="enter your email"
          className={classes.input}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <textarea
          id="moreInfo"
          placeholder="enter the model of your devices"
          className={classes.textarea}
          style={{ resize: "none" }}
          value={device}
          onChange={(e) => setDevice(e.target.value)}
        />
        <div className={classes.button}>
          <Button type="submit">Add my device to the list</Button>
        </div>
      </form> */}
    </div>
  );
}
