import React from "react"
import AllPoints from "./points/allPoints"


export default function Points(props) {

  const all_width = props.blocks.items.length * -(302 + 200 + 40)

  const [arrow, setArrow] = React.useState(0)
  


  return (
    <div>
      <AllPoints
        card_theme={all_width}
        blocks={props.blocks}
        arrow={arrow}
        setArrow={setArrow}
      />
    </div>
  )
}
