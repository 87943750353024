import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import Title from '../../layout/footer/title'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginRight: 30,
        '&:last-child': {
            marginRight: 0,
        },
        '& [class^="MuiTypography"]': {
            width: '100%',
        },
        '&:nth-child(1)': {
            '& .svg': {
                width: 105,
            },
        },
        '&:nth-child(2)': {
            '& .svg': {
                width: 127,
            },
        },
        '@media (max-width: 767px)': {
            marginRight: 0,
            marginBottom: 30,
            '&:last-child': {
                marginBottom: 0,
            },
            '&:nth-child(1)': {
                '& .svg': {
                    width: '60px !important',
                    height: 'auto',
                },
            },
            '&:nth-child(2)': {
                '& .svg': {
                    width: '80px !important',
                    height: 'auto',
                },
            },'&:nth-child(3)': {
                '& .svg': {
                    width: '100px !important',
                    height: 'auto',
                    
                },
            },
        },
    },
    card: {
        // backgroundColor: theme.palette.background.card,
        borderRadius: 22,
        padding: 15,
        width: 150,
        height: 158,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: 10,
        '& .addition': {
            marginBottom: '0px !important',
            marginTop: '30px !important',
        },
        '@media (max-width: 767px)': {
            height: 140,
            backgroundColor: theme.palette.background.card,
        },
    },
}))

export default function Size({ svg, name, subtitle }) {

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.card}>
                <img src={svg} alt={'size'} width="150" height="100" className="svg" />
                <Title name={name} />
            </div>
            <Typography variant="body2">
                {subtitle}
            </Typography>
        </div>
    )
}