import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles, Typography } from "@material-ui/core"

import FormCard from "../productCard/form"
import Cross from "../../static/svg/cross.svg"

const useStyle = makeStyles(theme => ({
  container: {
    pointerEvents: "none",

    position: "fixed",
    zIndex: 99,
    boxSizing: "border-box",
    width: "100%",
    maxWidth: "1440px",
    left: "50%",

    display: "flex",
    justifyContent: "flex-end",

    paddingLeft: 30,
    paddingRight: 30,
    "@media (max-width: 1024px)": {
      paddingLeft: "3vw",
      paddingRight: "3vw",
    },

    bottom: "2.08vw",
    transform: "translate(-50%, calc(100% + 2.08vw))",
    animation: `1s $slide2 forwards`,
    "@media (min-width:1440px)": {
      bottom: 30,
      transform: "translate(-50%, calc(100% + 30px))",
      animation: `1s $slide1 forwards`,
    },
    "@media (max-width:767px)": {
      bottom: "3.08vw",
      transform: "translate(-50%, calc(100% + 3.08vw))",
      animation: `1s $slide3 forwards`,
    },
  },
  "@keyframes slide1": {
    from: {
      transform: "translate(-50%, calc(100% + 30px))",
    },
    to: {
      transform: "translate(-50%, 0)",
    },
  },
  "@keyframes slide2": {
    from: {
      transform: "translate(-50%, calc(100% + 2.08vw))",
    },
    to: {
      transform: "translate(-50%, 0)",
    },
  },
  "@keyframes slide3": {
    from: {
      transform: "translate(-50%, calc(100% + 3.08vw))",
    },
    to: {
      transform: "translate(-50%, 0)",
    },
  },
  container_closes: {
    animation: `1s $slide_closes2 forwards`,
    "@media (min-width:1440px)": {
      animation: `1s $slide_closes1 forwards`,
    },
    "@media (max-width:767px)": {
      animation: `1s $slide_closes3 forwards`,
    },
  },
  "@keyframes slide_closes1": {
    from: {
      transform: "translate(-50%, 0)",
    },
    to: {
      transform: "translate(-50%, calc(100% + 30px))",
    },
  },
  "@keyframes slide_closes2": {
    from: {
      transform: "translate(-50%, 0)",
    },
    to: {
      transform: "translate(-50%, calc(100% + 2.08vw))",
    },
  },
  "@keyframes slide_closes3": {
    from: {
      transform: "translate(-50%, 0)",
    },
    to: {
      transform: "translate(-50%, calc(100% + 3.08vw))",
    },
  },
  wrapper: {
    pointerEvents: "auto",

    background: theme.palette.background.card,
    border: "1px solid rgba(255, 255, 255, 0.2)",

    width: "34.93vw",
    borderRadius: "1.52vw",
    padding: "1.52vw",
    "@media (min-width:1440px)": {
      width: 503,
      borderRadius: 22,
      padding: 22,
    },
    "@media (max-width:767px)": {
      width: "100%",
      borderRadius: "4.51vw",
      padding: "4.51vw",
    },
  },
  title_and_button: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  title: {
    fontFamily: "Krona One",
    lineHeight: 1.25,
    color: theme.palette.primary.accent,

    fontSize: "1.38vw",
    "@media (min-width:1440px)": {
      fontSize: 20,
    },
    "@media (max-width:767px)": {
      fontSize: "4.1vw",
    },
  },
  cross: {
    background: "transparent",
    padding: 0,
    minWidth: 0,
    border: "none",
    cursor: "pointer",

    width: "1.38vw",
    height: "1.38vw",
    "@media (min-width:1440px)": {
      width: 20,
      height: 20,
    },
    "@media (max-width:767px)": {
      width: "4.1vw",
      height: "4.1vw",
    },
  },
  text: {
    lineHeight: 1.28,

    marginTop: "0.69vw",
    fontSize: "0.97vw",
    "@media (min-width:1440px)": {
      marginTop: 10,
      fontSize: 14,
    },
    "@media (max-width:767px)": {
      marginTop: "2.05vw",
      fontSize: "2.87vw",
    },
  },
  form: {
    width: "100%",

    marginTop: "1.52vw",
    "@media (min-width:1440px)": {
      marginTop: 22,
    },
    "@media (max-width:767px)": {
      marginTop: "4.51vw",
    },

    "& input": {
      boxSizing: "border-box",
      width: "100%",
    },
    "& div": {
      alignSelf: "flex-start",
    },
  },
}))

export default function FormWithDelay({ delay }) {
  delay = delay ?? 10000
  const classes = useStyle()
  const data = useStaticQuery(graphql`
    {
      prismicFormWithDelay {
        data {
          accent_title
          text
          input_placeholder
          button_text
        }
      }
    }
  `)
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    const idTimeout = setTimeout(setShow, delay, true)
    return () => clearTimeout(idTimeout)
  }, [])

  const formCallback = React.useRef(null)

  const [pressClose, setPressClose] = React.useState(false)
  function close() {
    if (pressClose) return
    setPressClose(true)
    formCallback.current.classList.add(classes.container_closes)

    setTimeout(() => {
      setShow(false)
      setPressClose(false)
    }, 1000)
  }

  return show ? (
    <div ref={formCallback} className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.title_and_button}>
          <Typography className={classes.title}>
            {data.prismicFormWithDelay.data.accent_title}
          </Typography>

          <button onClick={close} className={classes.cross}>
            <Cross />
          </button>
        </div>

        <Typography className={classes.text}>
          {data.prismicFormWithDelay.data.text}
        </Typography>

        <div className={classes.form}>
          <FormCard
            placeholder={data.prismicFormWithDelay.data.input_placeholder}
            buttonText={data.prismicFormWithDelay.data.button_text}
            afterSuccesSubmit={close}
          />
        </div>
      </div>
    </div>
  ) : null
}
