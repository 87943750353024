import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { graphql, useStaticQuery } from "gatsby"
import Title from "../../mainComponents/title"
import AboveTitle from "../../mainComponents/aboveTitle"
import Text from "../../mainComponents/text"
import { useMediaQuery } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    margin: "0 auto",
    maxWidth: "1440px",
    overflow: "hidden",
    marginBottom: "180px",
    // "@media (max-width: 779px)": {
    //   display: "none",
    // },
    "@media (max-width: 1025px)": {
      marginBottom: "0px",
      marginTop: "0px",
    },
  },
  wrapperMobile: {
    height: "100%",
    marginTop: '35vw',
    //marginBottom: '20vh',
    "@media (min-width: 760px)": {
      marginTop: '10vh',
    },
    // "@media (min-width: 780px)": {
    //   display: "none",
    //   marginTop: '0',
    // },
  },
  textContainer: {
    //position: 'absolute',
    height: "fit-content",
    right: '0',
    width: '45vw',
    //width: "45vw",
    
    marginTop: "-15vw",
    zIndex: 2,
    "@media (max-width:1023px)": {
      marginTop: "20vh",
      width: '45vw',
    },
    "@media (max-width:1025px)": {
      marginTop: "-35vw",
      width: '55vw',
    },
    "@media (min-width:1720px)": {
      width: "35vw",
    },
    "@media (min-width:1920px)": {
      width: "30vw",
    },
    "@media (min-width:2300px)": {
      width: "25vw",
    },
    // "@media (min-width:2500px)": {
    //   marginTop: "25vw",
    // },
  },  
  textWrapper: {
    width: "100%",
    position: 'relative',
    margin: "0 0 0 auto",
    display: "flex",
    justifyContent: "flex-end",
  },
  imageContainer: {
    //position: "absolute",
    width: "90%",
    display: "flex",
    marginLeft: "7vw",
    justifyContent: "center",
    marginBottom: "15vh",
    "@media (min-width:2500px)": {
      marginBottom: "10vh",
      marginLeft: "0vw",
      width: "100%",
    },
    "@media (max-width: 1025px)": {
      width: "100%",
      marginLeft: "0",
    },
  },
  imageContainerMobile: {
    "@media (min-width: 401px)": {
      "&img": {
        //minWidth: "fit-content",
      },
      width: "100vw",
      overflowX: "scroll",
      overflowY: "hidden",
      height: '800px',
      marginBottom: '100px',
    },
    "@media (max-width: 400px)": {
      "&img": {
        //minWidth: "fit-content",
      },
      width: "100vw",
      overflowY: "hidden",
      overflowX: "scroll",
      height: '800px',
      marginBottom: '100px',
    },
  },

  textWrapperMobile: {
    width: "100%",
    margin: "0 0 0 auto",
    display: "flex",
    justifyContent: "center",
    marginLeft: "-25px",
    marginBottom: "30px",
    "@media (min-width: 768px)": {
      width: "70%",
      justifyContent: "flex-start",
      marginLeft: "25px",
    },
  },
  elementsContainer: {
    padding: "0 7vw",
    height: "35vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    "@media (min-width:1550px)": {
      height: "25vh",
    },
    "@media (min-width:1920px)": {
      height: "16vh",
      minHeight: '250px'
    },
    "@media (min-width:2500px)": {
      height: "2vh",
      minHeight: 'auto',
    },
    "@media (max-width: 770px)": {
      height: "100vh",
      display: "flex",
      flexDirection: "row",
      justifyContent: "left",
    },
    "@media (max-width: 400px)": {
      height: "800px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
  },
  elementsItem: {
    width: "20vw",    
    "@media (min-width: 400px)": {
      width: "20vh",
    },
    "@media (min-width:1920px)": {
      width: "15vh",
    },

  },
  elementShift: {
    marginLeft: "-70px",
    "@media (min-width:1920px)": {
      marginLeft: "-140px",
    },
    "@media (max-width: 770px)": {
      marginLeft: "-20px",
    },
    "@media (max-width: 400px)": {
      marginLeft: "-25px",
    },
  },
  elementsWrapper: {
    display: "flex",
    flexDirection: "column-reverse",
    justifyContent: "space-between",
    height: "100%",
    marginTop: "15vh",
    marginBottom: "10vh",
    "@media (min-width:1460px)": {
      marginTop: "13vh",
      marginBottom: "17vh",
    },
    "@media (min-width:1920px)": {
      marginTop: "14vh",
      marginBottom: "10vh",
      marginRight: "4vh",
    },
    "@media (min-width:2560px)": {
      marginTop: "9vh",
      marginBottom: "7vh",
      marginRight: "4vh",
    },
    "@media (max-width: 770px)": {
      height: "600px",
      marginTop: "0",
    },
  },
  arrowWrapper: {
    display: "flex",
    //position: 'absolute',
    width: '270px',
    marginTop: '40px',
    height: "50px",
    marginLeft: "15px",
    zIndex: '1',
    animation: "4s ease-in-out infinite",
    animationName: "$go-left-right",
    // "@media (min-width: 770px)": {
    //   display: "none",
    //   width: '0px',
    // },
  },
  "@keyframes go-left-right": {
    /* назовём анимацию: "go-left-right" */
    "0%:": {
      transform: "translate3d(0px, 0, 0px)",
      animationTimingFunction: "ease-in",
    },
    "50% ": {
      transform: "translate3d(120px, 0, 0px)",
      animationTimingFunction: "ease-out",
    },
    "100%": {
      transform: " translate3d(0px, 0, 0px)",
    },
  },
  arrow: {
    width: "13px",
    height: "13px",
    borderTop: "1px solid #00FF85",
    borderRight: " 1px solid #00FF85",
    marginRight: " 10px",
    transform: "rotate(45deg)",
  },
  firstLine: {
    display: "flex",
    justifyContent: "space-around",
    background: '#0D0D0D',
    flexDirection: "row",
    margin: "0 auto",
    width: '1000px',
    //paddingLeft: '400px',
    zIndex: '5',
    //paddingBottom: '50px'
    "@media (min-width: 400px)": {
      //paddingLeft: '300px',
    },
  },
  img: {
    //width: '1000px',
    height: 'auto',
    margin: '0 10px'
  },
  points: {
    width: '100vw',
    // height: '100vh'
  }
}))

export const ThirdBlockLazy = () => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query ThirdBlockLazyQuery {
      allPrismicLandingContent {
        edges {
          node {
            data {
              title {
                text
              }
              title2 {
                text
              }
              subtitle {
                text
              }
              subtitle2 {
                text
              }
              background {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1500) {
                      src
                      srcWebp
                    }
                  }
                }
                alt
              }
              mobile_image {
                localFile {
                    publicURL
                }
                alt
              }
              description {
                text
              }
              background3 {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      src
                      srcWebp
                    }
                  }
                }
                alt
              }
              description2 {
                text
              }
              body2 {
                ... on PrismicLandingContentBody2Element {
                  id
                  items {
                    element_description {
                      text
                    }
                    element_title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const title = data.allPrismicLandingContent.edges[0].node.data.title2.text
  const subtitle =
    data.allPrismicLandingContent.edges[0].node.data.subtitle2.text
  const description =
    data.allPrismicLandingContent.edges[0].node.data.description2.text


  const mobile = useMediaQuery("(max-width:767px)")

  return (
    <div className={classes.root}>
      {mobile ?
   <div className={classes.wrapperMobile}>
   <div className={classes.textWrapperMobile}>
     <div className={classes.textContainerMobile}>
       <AboveTitle number="03" title={subtitle} />
       <Title>
         <div dangerouslySetInnerHTML={{ __html: title }} />
       </Title>
       <Text>{description}</Text>
     </div>
   </div>
   <div className={classes.imageContainerMobile}>            
   <div className={classes.arrowWrapper}>
     <div className={classes.arrow}></div>
     <div className={classes.arrow}></div>
     <div className={classes.arrow}></div>
   </div>      
   <div style={{width: '1000px'}}>
   <img src={`${data.allPrismicLandingContent.edges[0].node.data.mobile_image.localFile.publicURL}`}/>
   </div>
   </div>
 </div>
      :
      <div className={classes.wrapper}>
        <div className={classes.imageContainer}>
          {/* <Desktop /> */}
          <img src={`${data.allPrismicLandingContent.edges[0].node.data.mobile_image.localFile.publicURL}`} width="1383" height="716" className={classes.img}/>
        </div>
        <div className={classes.textWrapper}>
          <div className={classes.textContainer}>
            <AboveTitle number="03" title={subtitle} />
            <Title>
              <div dangerouslySetInnerHTML={{ __html: title }} />
            </Title>
            <Text>{description}</Text>
          </div>
        </div>
      </div>}
      
    </div>
  )
}
