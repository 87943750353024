import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { graphql, useStaticQuery } from "gatsby"

import Title from '../../mainComponents/title'
import Text from '../../mainComponents/text'
import AboveTitle from '../../mainComponents/aboveTitle'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden !important',
    marginBottom: '100px',
    '@media (max-width: 1024px)': {
        marginTop: '3vw',
        marginBottom: '5vw',
      },
      '@media (max-width: 767px)': {
        marginTop: '0vw',
        marginBottom: '20vw',
      },
  },
  wrapper: {
      maxWidth: '1440px',
      width: '100%',
      '@media (max-width: 1024px)': {
        paddingLeft: '2.8vw',
        paddingRight: '3vw',
      },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingTop: '400px',
    paddingBottom: '89px',
    width: '100%',
    minHeight: '415px',
    '@media (max-width: 1024px)': {
        paddingLeft: '10vw',
        paddingTop: '500px',
        minHeight: '43vw',
      },
      '@media (max-width: 910px)': {
        paddingLeft: '10vw',
        paddingTop: '430px',
        minHeight: '43vw',
      },
    '@media (max-width: 767px)': {
        paddingTop: '127vw',
        minHeight: '75vw',
        paddingLeft: '10vw',
        paddingBottom: '0px',
      },
  },
  background: {
    zIndex: 0,
    width: 1653,
    height: '100%',
    position: 'relative',
    marginBottom: '-950px',
    '@media (max-width: 1024px)': {
        width: '135vw',
        marginBottom: '-100vw',
      },
    '@media (max-width: 767px)': {
        marginBottom: '-160vw',
      },
  },
  textDiv: {
    paddingRight: 30,
    maxWidth: '1090px',
    width: '1070px',
    zIndex: 1,
    '@media (max-width: 1424px)': {
      paddingRight: 0,
      width: '1090px',
    },
    '@media (max-width: 1330px)': {
      paddingRight: 0,
      width: '1050px',
    },
    '@media (max-width: 1220px)': {
      paddingRight: 0,
      width: '1000px',
    },
    '@media (max-width: 1120px)': {
      paddingRight: 0,
      width: '950px',
    },
    '@media (max-width: 1024px)': {
      maxWidth: '93vw',
    },
    '@media (max-width: 767px)': {
      width: '94%',
      maxWidth: '100vw',
    },
},
  title: {
    marginBottom: '20px',
    width: '100%',
    '@media (max-width: 1024px)': {
        marginBottom: '1vw',
        width: '90%',
      },
      '@media (max-width: 767px)': {
        marginBottom: '2.2vw',
        width: '90%',
      },
  },
  text: {
    width: '600px',
    '@media (max-width: 1024px)': {
        width: '60vw',
      },
    '@media (max-width: 767px)': {
        width: '70vw',
      },
  },
  img: {
    width: `100%`,
    height: `auto`,
    margin: `0 auto`,
    objectFit: "contain",
    objectPosition: 'center',
  },
}))

export const  SecondBlock= () =>  {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
  query SecondBlockQuery {
    allPrismicLandingContent {
      edges {
        node {
          data {
            title1 {
              text
            }
            subtitle1 {
              text
            }
            background1 {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1653) {
                    src
                    srcWebp
                  }
                }
              }
              alt
            }
            description1 {
              text
            }
          }
        }
      }
    }
  }
  
    
`)
  

  return (
    <div className={classes.root} >
        <div className={classes.background}>
            <picture>
                <sourse
                    className={classes.img}
                    width="1653" height="894"
                    srcSet={data.allPrismicLandingContent.edges[0].node.data.background1.localFile.childImageSharp.fluid.srcWebp }
                    type="image/webp"/>
                <img
                    className={classes.img}
                    width="1653" height="894"
                    srcSet={data.allPrismicLandingContent.edges[0].node.data.background1.localFile.childImageSharp.fluid.src}
                    alt={data.allPrismicLandingContent.edges[0].node.data.background1.alt} />
            </picture>
        </div>
        <div className={classes.wrapper}>
            <div className={classes.container}>
                <div className={classes.textDiv}>
                    <AboveTitle number="02" title={data.allPrismicLandingContent.edges[0].node.data.subtitle1.text} />
                    <div className={classes.title}>
                        <Title>
                            <div dangerouslySetInnerHTML={{ __html: data.allPrismicLandingContent.edges[0].node.data.title1.text}} className={classes.title}/>
                        </Title>
                    </div>
                    <div className={classes.text}>
                        <Text >
                            {data.allPrismicLandingContent.edges[0].node.data.description1.text}
                        </Text>
                    </div>
                </div>
            </div>
        </div>
  </div>
  )
}