import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from '@material-ui/core'
import Slider from 'infinite-react-carousel'
import './carousel.css'

const useStyles = makeStyles(theme => ({
    root: {
        '@media (max-width:1024px)': {
            padding: '0vw 10vw',
        },
        '@media (max-width:767px)': {
            padding: '0vw 5vw',
        },
    },
    image: {
        width: '100%',
        height: 452,
        objectFit: 'contain',
        cursor: 'grab',
        '&:active': {
            cursor: 'grabbing',
        },
        '@media (max-width:1024px)': {
            height: 452,
        },
        '@media (max-width:767px)': {
            height: 200,
        },
    },
}))

export default function ProductCarousel() {

    const classes = useStyles()

    const data = useStaticQuery(graphql`
        query Image {
            allPrismicProductCardBodyPhoto(sort: {fields: primary___position}) {
                edges {
                    node {
                        primary {
                            img {
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 1000) {
                                            src
                                            srcWebp
                                        }
                                    }
                                }
                                alt
                            }
                        }
                    }
                }
            }
        }
    `)

    const IsMobile = typeof window !== 'undefined' && window.matchMedia("(max-width: 767px)").matches
    const IsPad = typeof window !== 'undefined' && window.matchMedia("(max-width: 1024px)").matches
    const IsSmall = typeof window !== 'undefined' && window.matchMedia("(max-width: 1424px)").matches

    const Carousel = () => {
        switch(IsMobile) {
            case true:
                return  (
                    <Slider { ...settingsMobile }>
                        {img.map((img, i) => 
                            <picture className={classes.image} key={"image" + i}>
                                <source srcSet={img.node.primary.img.localFile.childImageSharp.fluid.srcWebp} className={classes.image} type="image/webp" />
                                <img srcSet={img.node.primary.img.localFile.childImageSharp.fluid.src} className={classes.image} alt={img.node.primary.img.alt} />
                            </picture>
                        )}
                    </Slider>
                )
            
            default:
                return PadCarousel(IsPad)
        }
    }

    const PadCarousel = () => {
        switch(IsPad) {
            case true:
                return  (
                    <Slider { ...settingsPad }>
                        {img.map((img, i) => 
                            <picture className={classes.image} key={"image2" + i}>
                                <source srcSet={img.node.primary.img.localFile.childImageSharp.fluid.srcWebp} width="790" height="452" className={classes.image} type="image/webp" />
                                <img srcSet={img.node.primary.img.localFile.childImageSharp.fluid.src} width="790" height="452" className={classes.image} alt={'PWR BOARD'} />
                            </picture>
                        )}
                    </Slider>
                )
            
            default:
                return SmallCarousel(IsSmall)
        }
    }

    const SmallCarousel = () => {
        switch(IsSmall) {
            case true:
                return (
                    <Slider { ...settingsSmall }>
                        {img.map((img, i) => 
                            <picture className={classes.image} key={"image3" + i}>
                                <source srcSet={img.node.primary.img.localFile.childImageSharp.fluid.srcWebp} className={classes.image} type="image/webp" />
                                <img srcSet={img.node.primary.img.localFile.childImageSharp.fluid.src} className={classes.image} alt={'PWR BOARD'} />
                            </picture>
                        )}
                    </Slider>
                )
            
            default:
                return (
                    <Slider { ...settings }>
                        {img.map((img, i) => 
                            <picture className={classes.image} key={"image4" + i}>
                                <source srcSet={img.node.primary.img.localFile.childImageSharp.fluid.srcWebp} className={classes.image} type="image/webp" />
                                <img srcSet={img.node.primary.img.localFile.childImageSharp.fluid.src} className={classes.image} alt={'PWR BOARD'} />
                            </picture>
                        )}
                    </Slider>
                )
        }
    }

    const settingsMobile =  {
        arrows: false,
        centerMode: false,
        dots: true,
        adaptiveHeight: false,
        arrowsBlock: false,
    }

    const settingsPad =  {
        arrows: false,
        centerMode: false,
        dots: true,
        adaptiveHeight: false,
        arrowsBlock: false,
    }

    const settingsSmall =  {
        arrows: false,
        centerMode: true,
        dots: true,
        centerPadding: 90,
        adaptiveHeight: false,
        arrowsBlock: false,
    }

    const settings =  {
        arrows: false,
        centerMode: true,
        dots: true,
        centerPadding: 290,
        adaptiveHeight: false,
        arrowsBlock: false,
    }

    const img = data.allPrismicProductCardBodyPhoto.edges

    return (
        <div className={classes.root}>
            {Carousel(IsMobile)}
        </div>
    )
}