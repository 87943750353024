import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import Li from './li'

const useStyles = makeStyles(theme => ({
    wrapper: {
        overflow: 'hidden',
        marginBottom: 20,
        height: props => 
            (props.length > props.realLength)
            ? (props.click === true)
                ? '100%'
                : 130
            : '100%',
        '@media (max-width: 1024px)': {
            marginBottom: 10,
            height: props => 
                (props.length > props.realLength)
                ? (props.click === true)
                    ? '100%'
                    : 'calc(1.2vw * 6 + 10px * 7)'
                : '100%',
        },
        '@media (max-width: 767px)': {
            height: props => 
                (props.length > props.realLength)
                ? (props.click === true)
                    ? '100%'
                    : '30vw'
                : '100%',
        },
    },
    ul: {
        columns: '2',
        WebkitColumns: '2',
        MozColumns: '2',
        listStyleType: 'none',
        padding: 0,
        margin: 0,
        '@media (max-width: 767px)': {
            columns: '1',
            WebkitColumns: '1',
            MozColumns: '1',
        },
        '& [class^="MuiTypography-root"]': {
            color: 'white',
            '@media (max-width: 767px)': {
                fontSize: '3vw',
            },
        },
        '& li': {
            paddingTop: 10,
        },
    },
    show: {
        marginTop: 0,
        fontFamily: 'Krona One',
        color: theme.palette.primary.accent,
        cursor: 'pointer',
        '@media (max-width: 767px)': {
            fontSize: '2.5vw',
        },
        '&::before': {
            content: '""',
            background:  props => 
                (props.click === true)
                ? 'none'
                : 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
            width: props => props.gradient,
            height: 46,
            position: 'absolute',
            marginTop: -66,
            '@media (max-width: 1024px)': {
                height: '10vw',
                marginTop: 'calc(-10vw)',
            },
            '@media (max-width: 767px)': {
                width: '35vw !important',
                height: '10vw',
                marginTop: 'calc(-2.4vw - 10vw)',
            },
        },
    },
}))

export default function Table({ data, realLength, gradient }) {

    const length = data.raw.length

    const [click, setClick] = React.useState(false)

    const classes = useStyles({ length, realLength, gradient, click })

    return (
        <div>
            <div className={classes.wrapper}>
                <ul className={classes.ul}>
                    {data.raw.map((model, i) => (
                        <Li model={model} key={"model" + i}/>
                    ))}
                </ul>
            </div>
            {(length > realLength) ?
                <Typography variant="body2" className={classes.show}
                    onClick={e => {
                        e.preventDefault();
                        setClick(!click)
                    }}
                >
                    Show {(click === true) ? "less" : "all"}
                </Typography>
            :
                null
            }
        </div>
    )

}