import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { graphql, useStaticQuery } from "gatsby"

import Title from '../../mainComponents/title'
import Text from '../../mainComponents/text'
import AboveTitle from '../../mainComponents/aboveTitle'


const useStyles = makeStyles(theme => ({
  root: {
   width: '100%',
   marginBottom: 100,
   '@media (max-width: 1024px)': {
    marginBottom: '10vw',
  },
  '@media (max-width: 767px)': {
    marginBottom: '15vw',
  },
  },
  title: {
    marginBottom: 20,
    maxWidth: 1247
  },
  text: {
    maxWidth: 600,
    
},
}))

export const  OpenSours = () =>  {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
  query OpenSoursQuery {
    allPrismicLandingContent {
      edges {
        node {
          data {
            title_open_source {
              text
            }
            subtitle_open_source {
              text
            }
            text_open_source {
              text
            }
          }
        }
      }
    }
  }
  
    
`)
  

  return (
    <div className={classes.root}>
        <AboveTitle number="05" title={data.allPrismicLandingContent.edges[0].node.data.subtitle_open_source.text} />
        <div className={classes.title}>
            <Title>
                <div dangerouslySetInnerHTML={{ __html: data.allPrismicLandingContent.edges[0].node.data.title_open_source.text}} className={classes.title}/>
            </Title>
        </div>
        <div className={classes.text}>
            <Text >
                {data.allPrismicLandingContent.edges[0].node.data.text_open_source.text}
            </Text>
        </div>
  </div>
  )
}