import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        '& span': {
            color: '#00FF85',
        },
    },
}))


export default function Title ({children}) {

    const classes = useStyles();
    
      return (
        <div>
            <Typography variant='h2' className={classes.root}>
                {children}
            </Typography>
        </div>
      )
    }