import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { graphql, useStaticQuery } from "gatsby"
import Title from "../../mainComponents/title"
import AboveTitle from "../../mainComponents/aboveTitle"
import Text from "../../mainComponents/text"
import { Helmet } from "react-helmet"
import { Typography } from "@material-ui/core"

import ArrowRight from "../../../static/svg/arrowRight.svg"

const useStyles = makeStyles(theme => ({
  wrapper: {
    maxHeight: 800,
    height: "100vh",
    maxWidth: "1440px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    overflow: "visible",
    "@media (max-width:1024px)": {
      minHeight: "100vh",
    },
    "@media (max-width:767px)": {
      justifyContent: "space-between",
      minHeight: "fit-content",
      height: props => `calc(${props.mobileViewPort}px * 100)`,
    },
  },
  textContainer: {
    height: "fit-content",
    maxWidth: 1200,
    zIndex: 2,
    marginBottom: 20,
    marginTop: 120,
    "@media (max-width:1024px)": {},
    "@media (max-width:767px)": {
      marginBottom: "3vw",
      marginTop: "23vh",
    },
  },
  ces__wrapper: {
    display: "flex",
    alignItems: "center",

    marginBottom: "4.86vw",
    "@media (min-width:1440px)": {
      marginBottom: 70,
    },
    "@media (max-width:767px)": {
      marginTop: 80,
      marginBottom: 0,
    },

    "& *": {
      margin: 0,
    },
  },
  ces__signature: {
    textTransform: "uppercase",
    lineHeight: 1.27,

    marginTop: "0.69vw",
    fontSize: "0.97vw",
    "@media (min-width:1440px)": {
      marginTop: 10,
      fontSize: 14,
    },
    "@media (max-width:767px)": {
      marginTop: "2.05vw",
      fontSize: "2.87vw",
    },
  },
  ces__logo: {
    width: "4.86vw",
    height: "2.77vw",
    "@media (min-width:1440px)": {
      width: 70,
      height: 40,
    },
    "@media (max-width:767px)": {
      width: "14.37vw",
      height: "8.21vw",
    },
  },
  ces__content: {
    position: "relative",

    marginLeft: "1.52vw",
    "@media (min-width:1440px)": {
      marginLeft: 22,
    },
    "@media (max-width:767px)": {
      marginLeft: "4.51vw",
    },
  },
  ces__title: {
    fontFamily: "Krona One",
    lineHeight: 1.25,

    fontSize: "1.18vw",
    "@media (min-width:1440px)": {
      fontSize: 17,
    },
    "@media (max-width:767px)": {
      fontSize: "3.49vw",
    },
  },
  ces__description: {
    opacity: 0.6,
    lineHeight: 1.27,

    marginTop: "0.69vw",
    fontSize: "0.97vw",
    "@media (min-width:1440px)": {
      marginTop: 10,
      fontSize: 14,
    },
    "@media (max-width:767px)": {
      marginTop: "2.05vw",
      fontSize: "2.87vw",
    },

    "& p + p": {
      marginTop: "0.41vw",
      "@media (min-width:1440px)": {
        marginTop: 6,
      },
      "@media (max-width:767px)": {
        marginTop: "2.05vw",
      },
    },
  },
  ces__link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",

    marginTop: "1.38vw",
    "@media (min-width:1440px)": {
      marginTop: 20,
    },
    "@media (max-width:767px)": {
      marginTop: "4.1vw",
    },
  },
  ces__link__text: {
    lineHeight: 1.27,

    fontSize: "0.97vw",
    "@media (min-width:1440px)": {
      fontSize: 14,
    },
    "@media (max-width:767px)": {
      fontSize: "2.87vw",
    },
  },
  ces__link__logo: {
    display: "flex",

    marginLeft: "0.41vw",
    width: "0.97vw",
    height: "0.97vw",
    "@media (min-width:1440px)": {
      marginLeft: 6,
      width: 14,
      height: 14,
    },
    "@media (max-width:767px)": {
      marginLeft: "1.23vw",
      width: "2.87vw",
      height: "2.87vw",
    },
  },

  imageContainer: {
    position: "absolute",
    width: "100%",
    left: 150,
    display: "flex",
    justifyContent: "flex-end",
    "@media (max-width:1309px)": {
      width: "120vw",
      top: "5vw",
      left: "15vw",
    },
    "@media (max-width:1024px) and (orientation : portrait)": {
      width: "130vh",
      top: "15vw",
      left: 0,
    },
    "@media (max-width:767px)": {
      width: "120vh",
      justifyContent: "flex-start",
      left: 0,
      top: "10vh",
    },
    "@media (max-width:320px)": {
      top: "15vh",
    },
    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      height: "100%",
      width: "110%",
      top: 0,
      // right: "-50px",
      background:
        "linear-gradient(0deg, rgba(13,13,13, .90) 23%, rgba(0,0,0,0) 69%)",
      "@media (max-width:376px)": {
        //display: 'none',
        bottom: 0,
        height: "70%",
        top: "auto",
      },
      "@media (max-width:1024px)": {
        background:
          "linear-gradient(0deg, rgba(13,13,13, .90) 30%, rgba(0,0,0,0) 80%)",
      },
    },
  },
  image: {
    width: "90%",
    "@media (max-width:1024px)": {
      width: "100%",
    },
  },
  img: {
    width: "100%",
    height: "auto",
  },
  title: {
    marginBottom: "20px",
    '& [class^="MuiTypography-root"]': {
      textShadow: "0px 0px 100px #000000",
    },
    "@media (max-width: 1024px)": {
      marginBottom: "1vw",
    },
    "@media (max-width: 767px)": {
      marginBottom: "2.2vw",
    },
  },
}))

export const MainBlock = () => {
  const [mobileViewPort, setMobileViewPort] = React.useState(null)
  React.useEffect(() => {
    setMobileViewPort(window.innerHeight * 0.01)
  }, [])

  const classes = useStyles({ mobileViewPort })
  const data = useStaticQuery(graphql`
    query MainBlock {
      allPrismicLandingContent {
        edges {
          node {
            data {
              title {
                text
              }
              subtitle {
                text
              }
              background {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 4000) {
                      src
                      srcWebp
                    }
                  }
                }
                alt
              }
              description {
                text
              }
            }
          }
        }
      }
      prismicLandingContent {
        data {
          ces_map {
            alt
            localFile {
              publicURL
            }
          }
          ces_icon {
            alt
            localFile {
              publicURL
            }
          }
          ces_first_screen_signature {
            html
          }
          ces_first_screen_title {
            html
          }
          ces_first_screen_description {
            html
          }
          ces_link
          ces_link_content
        }
      }
    }
  `)
  const backgroundImage1 =
    data.allPrismicLandingContent.edges[0].node.data.background.localFile
      .childImageSharp.fluid.src
  const backgroundImage2 =
    data.allPrismicLandingContent.edges[0].node.data.background.localFile
      .childImageSharp.fluid.srcWebp
  const alt = data.allPrismicLandingContent.edges[0].node.data.background.alt
  const subtitle =
    data.allPrismicLandingContent.edges[0].node.data.subtitle.text
  const title = data.allPrismicLandingContent.edges[0].node.data.title.text
  const description =
    data.allPrismicLandingContent.edges[0].node.data.description.text

  return (
    <div className={classes.wrapper}>
      <div className={classes.imageContainer}>
        <picture className={classes.image}>
          <source
            srcSet={backgroundImage2}
            width="1370"
            height="778"
            className={classes.img}
            type="image/webp"
            rel="preload"
          />
          <img
            srcSet={backgroundImage1}
            width="1370"
            height="778"
            className={classes.img}
            alt={alt}
            rel="preload"
          />
        </picture>
      </div>
      <div className={classes.textContainer}>
        <AboveTitle number="01" title={subtitle} />
        <div className={classes.title}>
          <Title>
            <div dangerouslySetInnerHTML={{ __html: title }} />
          </Title>
        </div>
        <div className={classes.defaultText}>
          <Text>{description}</Text>
        </div>
      </div>
    </div>
  )
}
