import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        maxWidth: 600,
        '@media (max-width: 1024px)': {
          maxWidth: '60vw',
        },
        '@media (max-width: 767px)': {
          maxWidth: '80vw',
        },
   },
}))


export default function Text ({children}) {

    const classes = useStyles();
    
      return (
        <div>
            <Typography variant='body1' className={classes.root}>
                {children}
            </Typography>
        </div>
      )
    }