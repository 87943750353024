import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles, Typography } from "@material-ui/core"

import FormCard from "../../productCard/form"
import Form from "../../productCard/form"
import Title from "../../mainComponents/title"
import Text from "../../mainComponents/text"
import RenderSocial from "./renderRelease"

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "1380px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: " center",
    // marginTop: 130,
    // "@media (max-width: 1024px)": {
    //   marginTop: 100,
    // },
    // "@media (max-width: 767px)": {
    //   marginTop: "16vw",
    // },
  },
  title: {
    textAlign: "center",
    margin: `0 auto`,
    marginBottom: "20px",
    width: "90%",
    "@media (max-width: 1024px)": {
      marginBottom: "1vw",
    },
    "@media (max-width: 767px)": {
      marginBottom: "2.2vw",
      width: "95%",
    },
  },
  text: {
    textAlign: "center",
    margin: `0 auto`,
  },
  social: {
    display: "flex",
    marginTop: "20px",
    "@media (max-width: 1024px)": {
      marginTop: "2.5vw",
    },
    "@media (max-width: 767px)": {
      marginTop: "4vw",
    },
  },
  pictureDiv: {
    overflow: "hidden",
    maxWidth: "1124px",
    // marginTop: "60px",
    background:
      "radial-gradient(50% 50% at 50% 50%, #212121 0%, rgba(13, 13, 13, 0) 100%)",
    "@media (max-width: 1440px)": {
      width: "90vw",
      overflow: "hidden",
    },
    // "@media (max-width: 1024px)": {
    //   marginTop: "4vw",
    // },
    "@media (max-width: 767px)": {},
  },
  picture: {
    width: `100%`,
    height: "auto",
    margin: `0 auto`,
    objectFit: "contain",
    objectPosition: "center top",
  },
  img: {
    marginBottom: '11.52vw',
    "@media (min-width:1440px)": {
      marginBottom: '166px',
    },
    "@media (max-width:767px)": {
      marginBottom: '26.48vw',
    },
  },
  body: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "@media (max-width:767px)": {

      flexDirection: "column",
    },
  },
  content: {
    width: "48.19vw",
    "@media (min-width:1440px)": {
      width: 694,
    },
    "@media (max-width:767px)": {
      width: "100%",

      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  subtitle: {
    fontFamily: "Krona One",
    lineHeight: 1.5,

    fontSize: "2.63vw",
    "@media (min-width:1440px)": {
      fontSize: 38,
    },
    "@media (max-width:767px)": {
      textAlign: "center",
      fontSize: "5.74vw",
    },

    "& *": {
      margin: 0,
    },

    "& span": {
      color: "#00FF85",
    },
  },
  text: {
    lineHeight: 1.5,

    marginTop: "1.52vw",
    width: "41.66vw",
    fontSize: "1.18vw",
    "@media (min-width:1440px)": {
      marginTop: 22,
      width: 600,
      fontSize: 17,
    },
    "@media (max-width:767px)": {
      textAlign: "center",
      marginTop: "4.51vw",
      width: "100%",
      fontSize: "3.49vw",
    },

    "& *": {
      margin: 0,
    },

    "& span": {
      color: "#00FF85",
    },
  },
  signature: {
    width: "auto",

    marginTop: "3.05vw",
    height: "1.38vw",
    "@media (min-width:1440px)": {
      marginTop: 44,
      height: 20,
    },
    "@media (max-width:767px)": {
      marginTop: "9.03vw",
      height: "4.1vw",
    },
  },
  form: {
    width: "43.75vw",
    "@media (min-width:1440px)": {
      width: 630,
    },
    "@media (max-width:767px)": {
      marginTop: "11.49vw",
      width: "100%",
    },

    "& input": {
      boxSizing: "border-box",
      width: "100%",
    },
    "& div": {
      alignSelf: "flex-start",
      "@media (max-width:767px)": {
        alignSelf: "center",
      },
    },
  },
}))


export const Release = () => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query ReleaseBlock {
      allPrismicLandingContent {
        edges {
          node {
            data {
              pre_order_subtitle {
                html
                text
              }
              pre_order_text {
                html
                text
              }
              pre_order_signature {
                alt
                localFile {
                  publicURL
                }
              }
              pre_order_input
              pre_order_button
              body1 {
                ... on PrismicLandingContentBody1SocialNetworks {
                  id
                  primary {
                    social_icon {
                      localFile {
                        publicURL
                      }
                      alt
                    }
                    social_link {
                      url
                    }
                  }
                  slice_type
                }
              }
              title4 {
                text
              }
              subtitle_release {
                text
              }
              background2 {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1380) {
                      src
                      srcWebp
                    }
                  }
                }
                alt
              }
            }
          }
        }
      }
    }
  `)

  const subtitle =
    data.allPrismicLandingContent.edges[0].node.data.pre_order_subtitle
  const text = data.allPrismicLandingContent.edges[0].node.data.pre_order_text
  const signature =
    data.allPrismicLandingContent.edges[0].node.data.pre_order_signature

  return (
    <div className={classes.root}>
      {/* <div className={classes.title}>
            <Title>
                <div dangerouslySetInnerHTML={{ __html: data.allPrismicLandingContent.edges[0].node.data.title4.text}} className={classes.title}/>
            </Title>
        </div>
        <div className={classes.text}>
            <Text >
                {data.allPrismicLandingContent.edges[0].node.data.subtitle_release.text}
            </Text>
        </div>
        <div className={classes.social}>
          {data.allPrismicLandingContent.edges[0].node.data.body1.map((dblock, i) =>
            <RenderSocial 
            block={dblock}
            i={i}
            key={"release" + i}
            type={dblock.slice_type} />
           )}
        </div>
        <div className={classes.form}>
            <Form />
        </div> */}

      <div className={classes.img}>
        <div className={classes.pictureDiv}>
          <picture>
            <sourse
              className={classes.picture}
              width="1124"
              height="653"
              srcSet={
                data.allPrismicLandingContent.edges[0].node.data.background2
                  .localFile.childImageSharp.fluid.srcWebp
              }
              type="image/webp"
            />
            <img
              className={classes.picture}
              width="1124"
              height="653"
              src={
                data.allPrismicLandingContent.edges[0].node.data.background2
                  .localFile.childImageSharp.fluid.src
              }
              alt={
                data.allPrismicLandingContent.edges[0].node.data.background2.alt
              }
            />
          </picture>
        </div>
      </div>

      <div className={classes.body}>
        <div className={classes.content}>
          <Typography className={classes.subtitle} component="div">
            <div
              dangerouslySetInnerHTML={{
                __html: subtitle.html.includes("<pre>")
                  ? subtitle.text
                  : subtitle.html,
              }}
            />
          </Typography>

          <Typography className={classes.text} component="div">
            <div
              dangerouslySetInnerHTML={{
                __html: text.html.includes("<pre>") ? text.text : text.html,
              }}
            />
          </Typography>

          
        </div>

        <div className={classes.form}>
          <FormCard
            className={classes.inputArea}
            placeholder={
              data.allPrismicLandingContent.edges[0].node.data.pre_order_input
            }
            buttonText={
              data.allPrismicLandingContent.edges[0].node.data.pre_order_button
            }
          />
        </div>
      </div>
    </div>
  )
}
