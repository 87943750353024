import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles, Divider, Typography } from "@material-ui/core"
import Carousel from "./carousel"
import Size from "./size"
import Spec from "./spec"
import Form from "./form"
import Title from "../mainComponents/title"
import Text from "../mainComponents/text"
import RenderSocial from "../landing/Release/renderRelease"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  wrapper: {
    maxWidth: theme.palette.width.limit,
    width: "calc(100% - 60px)",
    padding: "60px 0px 40px 0px",
    margin: "0px 30px",
    backgroundColor: theme.palette.background.bigCard,
    "@media (max-width:767px)": {
      width: "calc(100% - 10vw)",
      margin: "0vw 5vw",
    },
  },
  divider: {
    width: "calc(100% - 180px)",
    background: "white",
    opacity: 0.6,
    marginTop: 40,
    marginBottom: 40,
    "@media (max-width:1024px)": {
      width: "calc(100% - 60px)",
      opacity: 0.3,
      marginLeft: "auto",
      marginRight: "auto",
    },
    "@media (max-width:767px)": {
      width: "calc(100% - 10vw)",
      opacity: 0.3,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  product: {
    marginTop: 40,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  name: {
    marginTop: 10,
  },
  main: {
    marginBottom: 20,
    "@media (max-width:767px)": {
      maxWidth: "80vw",
    },
  },
  config: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    "@media (max-width:767px)": {
      display: "block",
      width: "90vw",
      overflow: "scroll",
      "&::-webkit-scrollbar": {
        display: "none !important",
      },
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width:767px)": {
      alignItems: "flex-start",
    },
  },
  size: {
    width: "fit-content",
  },
  title: {
    paddingLeft: 22,
    marginBottom: 30,
    "@media (max-width:1024px)": {
      paddingLeft: 30,
      width: "calc(100% - 60px)",
    },
    "@media (max-width:767px)": {
      paddingLeft: "10vw",
      width: "70vw",
    },
  },
  titleText: {
    color: "#fff",
    fontSize: 20,
    fontFamily: "Krona One",
  },
  bottom: {
    "& span": {
      color: theme.palette.primary.accent,
    },
  },
  allSpec: {
    "@media (max-width:767px)": {
      paddingLeft: "5vw",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  subtitle: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width:1024px)": {
      width: "calc(100% - 120px)",
    },
    "@media (max-width:767px)": {
      paddingLeft: "5vw",
      width: "80vw",
    },
  },
  form: {
    margin: "0 auto",
    marginTop: 30,
  },
  titleRelease: {
    textAlign: "center",
    margin: `0 auto`,
    marginBottom: "20px",
    width: "100%",
    "@media (max-width: 1024px)": {
      marginBottom: "1vw",
    },
    "@media (max-width: 767px)": {
      marginBottom: "2.2vw",
      width: "100%",
      marginLeft: "-5vw",
    },
  },
  textRelease: {
    textAlign: "center",
    margin: `0 auto`,
  },
  socialRelease: {
    display: "flex",
    marginTop: "20px",
    "@media (max-width: 1024px)": {
      marginTop: "2.5vw",
    },
    "@media (max-width: 767px)": {
      marginTop: "4vw",
    },
  },
}))

export default function ProductCard() {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query ProductCard {
      allPrismicProductCard {
        edges {
          node {
            data {
              subtitle {
                text
              }
              name {
                text
              }
              date {
                text
              }
              title_config {
                text
              }
              body1 {
                ... on PrismicProductCardBody1Configuration {
                  id
                  primary {
                    svg {
                      url
                    }
                    model_name {
                      text
                    }
                    subtitle1 {
                      text
                    }
                  }
                }
              }
              title_spec {
                text
              }
              body2 {
                ... on PrismicProductCardBody2Description {
                  id
                  primary {
                    title {
                      text
                    }
                    value {
                      text
                    }
                  }
                }
              }
              bottom_description {
                text
                html
                raw
              }
            }
          }
        }
      }
      allPrismicLandingContent {
        edges {
          node {
            data {
              body1 {
                ... on PrismicLandingContentBody1SocialNetworks {
                  id
                  primary {
                    social_icon {
                      localFile {
                        publicURL
                      }
                      alt
                    }
                    social_link {
                      url
                    }
                  }
                  slice_type
                }
              }
              title4 {
                text
              }
              subtitle_release {
                text
              }
              background2 {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1380) {
                      src
                      srcWebp
                    }
                  }
                }
                alt
              }
            }
          }
        }
      }
    }
  `)

  const config = data.allPrismicProductCard.edges[0].node.data.body1
  const spec = data.allPrismicProductCard.edges[0].node.data.body2

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.carousel}>
            <Carousel />
          </div>
          <div className={classes.product}>
            <div className={classes.main}>
              <Typography variant="body2">
                {data.allPrismicProductCard.edges[0].node.data.subtitle.text}
              </Typography>
              <Typography variant="h1" className={classes.name}>
                {data.allPrismicProductCard.edges[0].node.data.name.text}
              </Typography>
            </div>
            <Typography variant="body2">
              {data.allPrismicProductCard.edges[0].node.data.date.text}
            </Typography>
          </div>
          <div className={classes.content}>
            <Divider orientation="horizontal" className={classes.divider} />
            <div className={classes.size}>
              <div className={classes.title}>
                <Typography className={classes.titleText}>
                  {
                    data.allPrismicProductCard.edges[0].node.data.title_config
                      .text
                  }
                </Typography>
              </div>
              <div className={classes.config}>
                {config.map((size, i) => (
                  <Size
                    key={"size" + i}
                    svg={size.primary.svg.url}
                    name={size.primary.model_name.text}
                    subtitle={size.primary.subtitle1.text}
                  />
                ))}
              </div>
            </div>
            {/*<Divider orientation="horizontal" className={classes.divider} />
                        <div className={classes.spec}>
                            <div className={classes.title}>
                                <Typography className={classes.titleText}>
                                    {data.allPrismicProductCard.edges[0].node.data.title_spec.text}
                                </Typography>
                            </div>
                            <div className={classes.allSpec}>
                                {spec.map((setting, i) => <Spec key={"settings" + i} name={setting.primary.title.text} subtitle={setting.primary.value.text} />)}
                            </div>
                        </div>*/}

            {/* <Divider orientation="horizontal" className={classes.divider} />
                        <div className={classes.subtitle}>
                            <div className={classes.titleRelease}>
                                <Title>
                                    <div dangerouslySetInnerHTML={{ __html: data.allPrismicLandingContent.edges[0].node.data.title4.text}} className={classes.title}/>
                                </Title>
                            </div>
                            <div className={classes.textRelease}>
                                <Text >
                                    {data.allPrismicLandingContent.edges[0].node.data.subtitle_release.text}
                                </Text>
                            </div>
                            <div className={classes.socialRelease}>
                            {data.allPrismicLandingContent.edges[0].node.data.body1.map((dblock, i) =>
                                <RenderSocial 
                                block={dblock}
                                i={i}
                                key={"release" + i}
                                type={dblock.slice_type} />
                            )}
                            </div>
                            <Typography variant="body2" className={classes.bottom}>
                                <div dangerouslySetInnerHTML={{ __html: data.allPrismicProductCard.edges[0].node.data.bottom_description.raw[0].text}} />
                            </Typography>
                        </div>
                        <div className={classes.form}>
                            <Form />
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
