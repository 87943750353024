import React from 'react'
import { Typography } from '@material-ui/core'

export default function Li({ model }) {

    return (
        <li>
            <Typography variant="body2">
                {model.text}
            </Typography>
        </li>
    )
}