import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { graphql, useStaticQuery } from "gatsby"

import Title from '../../mainComponents/title'
import Text from '../../mainComponents/text'
import AboveTitle from '../../mainComponents/aboveTitle'
import RenderPoint from './slider/renderPoint'


const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '180px',
    display: 'flex',
    flexDirection: 'row',
    alignItems:'center',
    '@media (max-width: 1024px)': {
      display: 'block',
      marginBottom: '17vw'
    },
    '@media (max-width: 767px)': {
      display: 'block',
      marginBottom: '24vw',
    },
  },
  textContainer: {
    width: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: '104px',
    '@media (max-width: 1439px)': {
      marginRight: '9vw',
      width: '66vw',
    },
    '@media (max-width: 1024px)': {
      marginBottom: '6.5vw',
      width: '80vw',
    },
    '@media (max-width: 767px)': {
      width: '90vw',
    },
  },
  title: {
    marginBottom: 20,
  },
}))

export const  FourthBlock= () =>  {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
  query FourthBlockQuery {
    allPrismicLandingContent {
      edges {
        node {
          data {
            description3 {
              text
            }
            title3 {
              text
            }
            subtitle3 {
              text
            }
            body {
              ... on PrismicLandingContentBodyCard {
                id
                items {
                  full_description_link {
                    url
                  }
                  short_description {
                    text
                  }
                  title4 {
                    text
                  }
                  number {
                    text
                  }
                  link_title {
                    text
                  }
                }
                slice_type
              }
            }
          }
        }
      }
    }
  }
    
`)
  

  return (
    <div className={classes.root} >
        <div className={classes.textContainer}>
            <AboveTitle number="04" title={data.allPrismicLandingContent.edges[0].node.data.subtitle3.text} />
            <div className={classes.title}>
                <Title>
                    <div dangerouslySetInnerHTML={{ __html: data.allPrismicLandingContent.edges[0].node.data.title3.text}}/>
                </Title>
            </div>
            <div className={classes.text}>
                <Text >
                    {data.allPrismicLandingContent.edges[0].node.data.description3.text}
                </Text>
            </div>
        </div>
        <div>
             {data.allPrismicLandingContent.edges[0].node.data.body.map((dblock, i) => (
              <RenderPoint
              block={dblock}
              i={i}
              key={"block_name4" + i}
              type={dblock.slice_type}
              />
            ))}
         </div>
  </div>
  )
}