import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from '@material-ui/core'
import Form from './form'
import Text from '../mainComponents/text'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    container: {
        maxWidth: 1440,
        width: 'calc(100% - 60px)',
        display: 'flex',
        justifyContent: 'space-between',
        
        '@media (max-width: 767px)': {
            margin: '0 auto',
            display: 'block',
            justifyContent: 'center',
    },
    },
    text: {
        '@media (max-width: 1439px)': {
            width: '40vw',
        },
        '@media (max-width: 1024px)': {
            width: '37vw',
        },
        '@media (max-width: 767px)': {
            marginBottom: '7vw',
            width: '100%',
    },
    },
}))

export default function FormCamera() {

    const classes = useStyles()

    const data = useStaticQuery(graphql`
        query FormCamera {
            allPrismicLandingContent {
                edges {
                    node {
                        data {
                            text_for_form {
                                text
                            }
                        }
                    }
                }
            }
        }
    `)

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <div className={classes.text}>
                    <Text>
                    {data.allPrismicLandingContent.edges[0].node.data.text_for_form.text}
                    </Text>
                </div>
                <div className={classes.form}>
                    <Form />
                </div>
            </div>
        </div>
    )
}