import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { graphql, useStaticQuery } from "gatsby"

const useStyles = makeStyles(theme => ({
    root: { 
        margin: '0 auto',
        maxWidth: 900,
        marginTop: 50,
        paddingLeft: 30,
        paddingRight: 30,
        '@media (max-width: 1024px)': {
          marginTop: '6vw',
          paddingLeft: '4vw',
          paddingRight: '4vw',
        },
        '@media (max-width: 768px)': {
          marginTop: '6vw',
          paddingLeft: '9vw',
          paddingRight: '9vw',
        },
        '@media (max-width: 767px)': {
          paddingLeft: '13vw',
          paddingRight: '13vw',
          marginTop: '5vw',
        },
        '@media (max-width: 540px)': {
          paddingLeft: '10vw',
          paddingRight: '10vw',
          marginTop: '5vw',
        },
        '@media (max-width: 414px)': {
          paddingLeft: '13vw',
          paddingRight: '13vw',
          marginTop: '5vw',
        },
        '@media (max-width: 360px)': {
          paddingLeft: '12vw',
          paddingRight: '12vw',
          marginTop: '5vw',
        },
        '@media (max-width: 320px)': {
          paddingLeft: '6vw',
          paddingRight: '6vw',
          marginTop: '5vw',
        },
        '@media (max-width: 280px)': {
          paddingLeft: '10.6vw',
          paddingRight: '10.6vw',
          marginTop: '5vw',
        },
    },
    text: {
        color: "#fff",
        fontSize: 14,
        fontFamily: 'Krona One',
        lineHeight: '210%',
        textAlign: 'center',
        '& span': {
            color: '#00FF85',
        },
        '& a': {
            color: '#FFFFFF',
            textDecoration: 'none',
            fontFamily: 'Space Grotesk',
            fontSize: 17,
            fontWeight: 400,
            paddingRight: 34,
            paddingLeft: 14,
            "&::after": {
              content: '""',
              position: 'absolute',
              backgroundImage: props => `url(${props.urlArrow})`,
              backgroundPosition: 'center', 
              backgroundSize: 'cover', 
              backgroundRepeat: 'no-repeat',
              marginTop: 7,
              marginLeft: 6,
              height: 14,
              width: 14,
          },
        },
        '@media (max-width: 767px)': {
            color: "#fff",
            fontSize: 14,
            fontFamily: 'Krona One',
            '& span': {
                color: '#00FF85',
            },
            '& a': {
                color: '#FFFFFF',
                textDecoration: 'none',
                fontFamily: 'Space Grotesk',
                fontSize: 17,
                fontWeight: 400,
            },
        },
    },
}))

export const  LinkSurvey= () =>  {
  const data = useStaticQuery(graphql`
  query LinkSurveyQuery {
    allPrismicLandingContent {
      edges {
        node {
          data {
            body3 {
              ... on PrismicLandingContentBody3SurveyLink {
                primary {
                  survey {
                    text
                  }
                }
                slice_type
              }
            }
            arrow {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`)
const urlArrow = data.allPrismicLandingContent.edges[0].node.data.arrow.localFile.publicURL
const classes = useStyles({urlArrow});
  

  return (
      <div className={classes.root}>
        <div className={classes.text}>
            {data.allPrismicLandingContent.edges[0].node.data.body3.map((link, i) => <div dangerouslySetInnerHTML={{ __html: link.primary.survey.text }} key={"link_survey" + i}/>  )}
        </div>
      </div>
  )
}