import React from "react"
import SocialLink from './social'


const RenderRelease = props => {
  const blockInner = props.block
  const contentType = props.block.slice_type



  let contentBlock
  
  switch (contentType) {
    case "social_networks":
      contentBlock = (
            <SocialLink
                link={blockInner} />
    )
      break
    default:
      return ""
  }
  return contentBlock
}
export default  RenderRelease