import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import Arrow from '../../../../../static/svg/arrow.svg'

const useStyles = makeStyles(theme => ({
    mainDiv: {
         marginBottom: '0vw',
        '@media (max-width: 1024px)': {
            marginBottom: '6.5vw',
        },
          '@media (max-width: 767px)': {
            marginTop: '14vw',
          },
    },
    container: {
        marginTop: 15,
        '&:first-child': {
            marginTop: 0,
        },
        '@media (max-width:1024px)': {
            width: 'fit-content',
            marginTop: '0.94vw',
        },
        '@media (max-width:767px)': {
            marginTop: 15,
        },
    },
    root: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiTypography-root': {
            fontSize: 14,
            '@media (max-width:1024px)': {
                fontSize: 14,
            },
            '@media (max-width:767px)': {
                fontSize: 13,
            },
            '@media (max-width:344px)': {
                fontSize: 12,
            },
        },
    },
    rootLink: {
        height: 43,
        display: 'flex',
        alignItems: 'flex-start',
    },
    link: {
        textDecoration: 'none',
    },
    icon: {
        width: 14,
        marginRight: props =>
            props.hover ?
                -4.2
            :
                0,
        marginTop: props =>
            props.hover ?
                -4.2
            :
                0,
        marginLeft: props =>
            props.hover ?
                10.2
            :
                6,
        transition: '.3s all ease',
        transform: props =>
            props.hover ?
                'scale(1.3)'
            :
                'scale(1)',
        '@media (min-width:768px) and (max-width:1024px)': {
            width: 14,
        },
    },
    empty: {
        height: 43,
    },
}))

export default function SliderLink(props) {

    const [hover, setHover] = React.useState(false)

    const classes = useStyles({ hover })

    return (
            <div className={classes.container}>
                {props.inner.full_description_link.url === "" ? 
                <div className={classes.empty}></div>
                :
                <div className={classes.mainDiv}>
                    <a href={props.inner.full_description_link.url} target="_blank" rel="noopener noreferrer" className={classes.link} onMouseEnter={() => {setHover(true)}} onMouseLeave={() => {setHover(false)}}>
                        <div className={classes.rootLink}>
                            <div className={classes.root}>
                                <Typography variant="body1">
                                    {props.inner.link_title.text}
                                </Typography>
                                <div className={classes.icon}>
                                    <Arrow />
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                }
            </div>
    )
}