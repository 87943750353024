import React from 'react'
import { makeStyles, Typography, Divider } from '@material-ui/core'
import Text from '../../../../mainComponents/text'
import Linka from './link'

const useStyles = makeStyles(theme => ({
    main: {
        zIndex: 0,
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginRight: '40px',
        '@media (max-width:1024px)': {
            marginRight: '2.6vw',
        },
        '@media (max-width: 767px)': {
            marginRight: '4vw',
          },
    },
    container: {
        padding: '80px 100px',
        width: 'fit-content',
        borderRadius: 1000,
        display: 'flex',
        minHeight: '156px',
        boxShadow: 'inset 0px 0px 0px 1px rgba(255, 255, 255, 0.5)',
        '@media (max-width:1024px)': {
            padding: '7vw 11vw',
            minHeight: '181px',
        },
        '@media (max-width: 767px)': {
            padding: '7vw 17vw',
            minHeight: '130px',
          },
          '@media (max-width: 540px)': {
            padding: '7vw 13vw',
            minHeight: '180px',
          },
          '@media (max-width: 414px)': {
            padding: '7vw 13vw',
            minHeight: '155px',
          },
          '@media (max-width: 411px)': {
            padding: '7vw 13vw',
          },
          '@media (max-width: 375px)': {
            padding: '7vw 13vw',
            minHeight: '145px',
          },
          '@media (max-width: 320px)': {
            padding: '7vw 13vw',
            minHeight: '130px',
          },
    },
    textContainer: {
        width: '302px',
        '@media (max-width: 1024px)': {
            width: '40vw',
          },
        '@media (max-width: 767px)': {
            width: '65vw',
        },
        // '@media (max-width: 320px)': {
        //     width: '55vw',
        // },
        // '@media (max-width: 280px)': {
        //     width: '67.5vw',
        // },
    },
    linkContainer: {
        display: 'flex',
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginBottom: 20,
        '@media (max-width: 767px)': {
            marginBottom: 15,
        },
    },
    titleText: {
        color: "#fff",
        fontSize: 18,
        fontFamily: 'Krona One',
        '@media (max-width: 767px)': {
            fontSize: '3.5vw',
        },
        '@media (max-width: 320px)': {
           fontSize: '4vw',
          },
          '@media (max-width: 280px)': {
            fontSize: '5vw',
          },
    },
    link: {
        textDecoration: 'none',
        // marginBottom: 25,
        '& [class^="makeStyles-container"]': {
            width: 'fit-content',
        },
    },
    divider: {
        background: `${theme.palette.primary.accent}`,
        width: 1,
        marginRight: 20,
    },
    number: {
        fontSize: 18,
        fontFamily: 'Krona One',
        color: `${theme.palette.primary.accent}`,
        marginRight: 7,
        '@media (max-width: 767px)': {
            fontSize: '3.5vw',
        '@media (max-width: 320px)': {
            marginRight: 5,
        },
        },
    },
    icon: {
        width: 14,
        marginLeft: 6
    },
    text: {
        lineHeight: '160%',
        '@media (max-width: 767px))': {
            lineHeight: '140%'
        },
    }
}))

export default function Point(props) {

    const classes = useStyles()

    return (
        <div className={classes.main}>
            <div className={classes.root}  style={{
                transform: `translate3d(${props.arrow}px, 0px, 0px)`,
                transition: 'transform 0.4s ease 0s', }}>
                <div className={classes.link}>
                < Linka 
                inner={props.inner} />
                </div>
                <div className={classes.container}>
                    <div className={classes.textContainer}>
                        <div className={classes.title}>
                            <Typography  className={classes.number}>
                                {props.inner.number.text}
                            </Typography>
                            <Divider orientation="vertical" flexItem className={classes.divider} />
                            <Typography className={classes.titleText}>
                                {props.inner.title4.text}
                            </Typography>
                        </div>
                        <Text>
                            <Typography className={classes.text}>
                            {props.inner.short_description.text}
                            </Typography>
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    )
}