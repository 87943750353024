import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles(theme => ({
    root: {
        marginRight: '30px',
        transition: '.3s all ease',
        '-webkit-transition': '.3s all ease',
        '&:hover': {
            transform: 'scale(1.1)',
            '-webkit-transform': 'scale(1.1)',
        },
        '&:last-child': {
            marginRight: 0,
        },
        '@media (max-width:1024px)': {
            marginRight: '2vw',
            '&:last-child': {
                marginRight: 0,
            },
        },
        '@media (max-width:767px)': {
            marginRight: '2.5vw',
            '&:last-child': {
                marginRight: 0,
            },
        },
    },
    link: {
        textDecoration: 'none',
    },
    wrapper: {
        width: '74px',
        height: 'auto',
        '@media (max-width:1024px)': {
            width: '6.5vw',
        },
        '@media (max-width:767px)': {
            width: '13vw',
        },
    },
    img: {
        width: '100%',
        height: 'auto',
    },
}))

export default function SocialLink(props) {
    const classes = useStyle()
    return (
        <div className={classes.root}>
            <a href={props.link.primary.social_link.url} target="_blank" rel="noopener noreferrer" className={classes.link}>
                <div className={classes.wrapper}>
                    <img src={props.link.primary.social_icon.localFile.publicURL}
                        width="74" height="66"
                        className={classes.img}
                        alt={props.link.primary.social_icon.alt} />
                </div>
            </a>
        </div>
    )
}