import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles, Typography } from "@material-ui/core"
import Button from "../../mainComponents/button"

import FormCard from "../../productCard/form"

const useStyle = makeStyles(theme => ({
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    padding: "0.69vw",
    "@media (min-width:1440px)": {
      padding: 10,
    },
    "@media (max-width:767px)": {
      padding: 0,

      flexDirection: "column",
    },
  },
  title: {
    textAlign: "center",
    fontFamily: "Krona One",
    lineHeight: 1.5,
    textTransform: "uppercase",

    fontSize: "5vw",
    "@media (min-width:1440px)": {
      fontSize: 72,
    },
    "@media (max-width: 1024px)": {},
    "@media (max-width:767px)": {
      marginLeft: 0,
      marginTop: "4.51vw",
      fontSize: "10.26vw",
    },

    "& *": {
      margin: 0,
    },

    "& span": {
      color: "#00FF85",
    },
  },
  body: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginTop: "3.05vw",
    "@media (min-width:1440px)": {
      marginTop: 44,
    },
    "@media (max-width:767px)": {
      marginTop: "9.03vw",

      flexDirection: "column",
    },
  },
  content: {
    width: "48.19vw",
    "@media (min-width:1440px)": {
      width: 694,
    },
    "@media (max-width:767px)": {
      width: "100%",

      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  text: {
    lineHeight: 1.5,

    marginTop: "1.52vw",
    width: "41.66vw",
    fontSize: "1.18vw",
    "@media (min-width:1440px)": {
      marginTop: 22,
      width: 600,
      fontSize: 17,
    },
    "@media (max-width:767px)": {
      textAlign: "center",
      marginTop: "4.51vw",
      width: "100%",
      fontSize: "3.49vw",
    },

    "& *": {
      margin: 0,
    },

    "& span": {
      color: "#00FF85",
    },
  },
  signature: {
    width: "auto",

    marginTop: "3.05vw",
    height: "1.38vw",
    "@media (min-width:1440px)": {
      marginTop: 44,
      height: 20,
    },
    "@media (max-width:767px)": {
      marginTop: "9.03vw",
      height: "4.1vw",
    },
  },
  form: {
    width: "43.75vw",
    "@media (min-width:1440px)": {
      width: 630,
    },
    "@media (max-width:767px)": {
      marginTop: "11.49vw",
      width: "100%",
    },

    "& input": {
      boxSizing: "border-box",
      width: "100%",
    },
    "& div": {
      alignSelf: "flex-start",
      "@media (max-width:767px)": {
        alignSelf: "center",
      },
    },
  },
  reserve: {
    textAlign: "center",
    fontFamily: "Krona One",
    lineHeight: "150%",

    margin: "100px 0px 35px 0px",
    fontSize: "2.63vw",
    fontWeight: 300,

    "@media (min-width:1440px)": {
      fontSize: 38,
    },
    "@media (max-width:767px)": {
      width: '82vw',
      marginTop: "17.86vw",
      fontSize: "5.74vw",
    },

    "& *": {
      margin: 0,
    },

    "& span": {
      color: "#00FF85",
    },
  },
  subtitle: {
    fontFamily: "Space Grotesk",
    textAlign: "center",
    fontSize: "1.18vw",
    fontWeight: 200,
    lineHeight: "150%",

    marginBottom: 35,

    "@media (min-width:1440px)": {
      fontSize: "17px",
    },
    "@media (max-width:767px)": {
      fontSize: "3.49vw",
    },

    "& span": {
      color: "#00FF85",
    },
  },
  buttonContainer: {
    "& button": {
      textDecoration: 'none',
      alignItems: "center !important",
      textAlign: "center !important",

      paddingRight: 49,
      paddingLeft: 49,
    },
    "& p": {
      textDecoration: 'none',
      fontSize: "1vw !important",
      lineHeight: "1.18vw !important",
      fontWeight: 300,
      "@media (min-width:1440px)": {
        fontSize: "14px",
        lineHeight: "17px",
      },
      '@media (max-width: 1024px)': {
        fontSize: '1.4vw !important',
    },
      "@media (max-width:767px)": {
        fontSize: "2.87vw !important",
        lineHeight: '1 !important',
      },
    },
    marginBottom: '11.52vw',
    "@media (min-width:1440px)": {
      marginBottom: '166px',
    },
    "@media (max-width:767px)": {
      marginBottom: "26.48vw",
    },
  },
  buttonStyle: {
    textDecoration: 'none',
  },
}))

export const PreOrder = () => {
  const classes = useStyle()
  const data = useStaticQuery(graphql`
    {
      allPrismicLandingContent {
        edges {
          node {
            data {
              pre_order_title {
                html
                text
              }
              reserve {
                html
                text
              }
              text_subtitle {
                html
                text
              }
              reserve_button
              reserve_link {
                url
              }
            }
          }
        }
      }
    }
  `)

  const title = data.allPrismicLandingContent.edges[0].node.data.pre_order_title
  const reserve = data.allPrismicLandingContent.edges[0].node.data.reserve
  const subtitle =
    data.allPrismicLandingContent.edges[0].node.data.text_subtitle
  const button = data.allPrismicLandingContent.edges[0].node.data.reserve_button

  console.log(reserve, "reserve")
  return (
    <>
      <div className={classes.header}>
        <Typography className={classes.title} component="div">
          <div
            dangerouslySetInnerHTML={{
              __html: title.html.includes("<pre>") ? title.text : title.html,
            }}
          />
        </Typography>

        <Typography className={classes.reserve} component="div">
          <div
            dangerouslySetInnerHTML={{
              __html: reserve.html.includes("<pre>")
                ? reserve.text
                : reserve.html,
            }}
          />
        </Typography>

        <Typography className={classes.subtitle} component="div">
          <div
            dangerouslySetInnerHTML={{
              __html: subtitle.html.includes("<pre>")
                ? subtitle.text
                : subtitle.html,
            }}
          />
        </Typography>
        <div className={classes.buttonContainer}>
          <a href={data.allPrismicLandingContent.edges[0].node.data.reserve_link.url} target="_blank" rel="noopener noreferrer" className={classes.buttonStyle}>
            <Button>
              {button}
            </Button>
          </a>
        </div>
      </div>
    </>
  )
}
