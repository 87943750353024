import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"


const useStyles = makeStyles(theme => ({
    root:{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginBottom: '30px',
        '@media (max-width: 1024px)': {
          marginBottom: '3vw',
        },
        '@media (max-width: 767px)': {
          marginBottom: '5vw',
        },
    },
    numberDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        background: theme.palette.primary.main,
        border: '1px solid #fff',
        borderRadius: '1000px',
        padding: '5px 40px 5px 7px',
        marginRight: '5px',
        '@media (max-width: 1024px)': {
          padding: '0.4vw 3.4vw 0.4vw 0.5vw',
        },
        '@media (max-width: 767px)': {
          padding: '0.8vw 6vw 0.8vw 1.4vw',
          marginRight: '1.2vw',
        },
    },
    number: {
      color: theme.palette.background.main,
      fontSize: 20,
      fontFamily: 'Krona One',
      '@media (max-width: 1024px)': {
          fontSize: '1.6vw',
      },
      '@media (max-width: 767px)': {
          fontSize: '3vw',
      },
    },
    titleDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        border: '1px solid #fff',
        borderRadius: '1000px',
        padding: '5px 12px 5px 60px',
        '@media (max-width: 1024px)': {
          padding: '0.4vw 0.8vw 0.4vw 5.8vw',
        },
       '@media (max-width: 767px)': {
          padding:  '0.8vw 1.4vw 0.8vw 10vw',
        },
    },
    titleText: {
      color: theme.palette.primary.main,
      fontSize: 20,
      fontFamily: 'Krona One',
      '@media (max-width: 1024px)': {
          fontSize: '1.6vw',
      },
      '@media (max-width: 767px)': {
          fontSize: '3vw',
      },
    },
}))


const AboveTitle = props => {
const classes = useStyles()
    return (
        <div className={classes.root} >
          <div className={classes.numberDiv}>
            <Typography className={classes.number}>
                {props.number}
            </Typography>
          </div>
          <div className={classes.titleDiv}>
          <Typography className={classes.titleText}>
                {props.title}
            </Typography>
          </div>
        </div>
      )
    }
export default AboveTitle