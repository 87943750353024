import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  MainBlock,
  Release,
  SecondBlock,
  FourthBlock,
  OpenSours,
  LinkSurvey,
  ThirdBlockLazy,
  PreOrder,
} from "../components/landing"
import ProductCard from "../components/productCard"
import FeaturedCarousel from "../components/productCard/featuredCarousel"
import Supported from "../components/supportedEquipment"
import FormCamera from "../components/form"
import FormWithDelay from "../components/formWithDelay"

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.main,
  },
  container: {
    margin: "0 auto",
    maxWidth: "1440px",
    paddingLeft: 30,
    paddingRight: 30,
    "@media (max-width: 1024px)": {
      paddingLeft: "3vw",
      paddingRight: "3vw",
    },
  },
  fourthBlock: {
    margin: "0 auto",
    maxWidth: "1440px",
    paddingLeft: 30,
    "@media (max-width: 1024px)": {
      paddingLeft: "3vw",
    },
  },
  wrapper: {
    margin: "0 auto",
    maxWidth: "1440px",
    paddingLeft: 30,
    paddingRight: 30,

    "@media (max-width: 1024px)": {
      paddingLeft: "3vw",
      paddingRight: "3vw",
    },

    marginTop: "11.11vw",
    "@media (min-width: 1440px)": {
      marginTop: 160,
    },
    "@media (max-width: 767px)": {
      marginTop: "16.42vw",
    },
  },
}))

export default function MainPage() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Layout>
        <div id="advantages" />
        <div className={classes.container}>
          <SEO />
          <MainBlock />
        </div>
        <SecondBlock />
        {/* <ThirdBlock/> */}
        <ThirdBlockLazy />
        <div className={classes.fourthBlock}>
          <FourthBlock />
        </div>
        <div id="modules" />
        <div className={classes.container}>
          <OpenSours />
        </div>
        <ProductCard />

        <div id="featured" />
        <div className={classes.wrapper}>
          <FeaturedCarousel />
        </div>

        <LinkSurvey />
        <div id="support" />
        <Supported />
        <FormCamera />

        <div id="pre_order" />
        <div className={classes.wrapper}>
          <PreOrder />
        </div>

        <div id="contact" />
        <div className={classes.container}>
          <Release />
        </div>

        <FormWithDelay delay={10000} />
      </Layout>
    </div>
  )
}
