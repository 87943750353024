import React from "react"
import PointContainer from './index'


const RenderBlock = props => {
  const blockInner = props.block
  const contentType = props.block.slice_type


  let contentBlock
  
  switch (contentType) {
    case "card":
      contentBlock = (
        <div>
            <PointContainer
              blocks={blockInner}/>
        </div>
    )
      break
    default:
      return ""
  }
  return <div>{contentBlock}</div>
}
export default RenderBlock