import React from 'react'
import Points from './index'




export default function TabPanels(props) {
    const clickLeftArrow = () => {
        var value = props.arrow + 542

        if (value > 0) {
            value = props.arrow
        } else {
            value = props.arrow + 542
        }

        props.setArrow(value)
    }

    const clickRightArrow = () => {
        var value = props.arrow - 542

        if (value > props.card_theme) {
            value = props.arrow - 542
        } else {
            value = 0
        }

        props.setArrow(value)
    }


    return (
        <div >
            <Points
                arrow={props.arrow}
                blocks={props.blocks}
                clickLeftArrow={clickLeftArrow}
                clickRightArrow={clickRightArrow}
            />
        </div>
    )
}
