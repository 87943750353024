import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import Title from '../../layout/footer/title'

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 15,
        width: 625,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .addition': {
            marginBottom: 0,
        },
        '@media (max-width:1024px)': {
            width: '50vw',
        },
        '@media (max-width:767px)': {
            width: 'fit-content',
            display: 'block',
            marginTop: 32,
        },
    },
    subtitle: {
        width: 150,
        '@media (max-width:767px)': {
            marginTop: 12,
            width: '60vw',
        },
    },
}))

export default function Spec({ name, subtitle }) {

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Title name={name} />
            <Typography variant="body2" className={classes.subtitle}>
                {subtitle}
            </Typography>
        </div>
    )
}