import React from "react"
import { makeStyles, useMediaQuery, Typography } from "@material-ui/core"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import FormCard from "../../productCard/form"

import ArrowRight from "../../../static/svg/arrowRight.svg"

const useStyle = makeStyles(theme => ({
  wrapper: {
    position: "relative",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    "& > *": {
      position: "relative",
      zIndex: 1,
    },
  },
  wrapper_images: {
    position: "absolute",
    width: "100vw",
    maxWidth: 1440,
    height: "100%",

    left: "50%",
    transform: "translateX(-50%)",
  },
  image_left: {
    position: "absolute",
    left: 0,
    zIndex: 0,

    top: "50%",
    transform: "translateY(-50%)",

    display: "block",

    width: "29.3vw",
    height: "57.87vw",
    "@media (min-width:1440px)": {
      width: 422,
      height: 747,
    },
    "@media (max-width:767px)": {
      width: "26.89vw",
      height: "70.02vw",
    },
  },
  image_right: {
    position: "absolute",
    right: 0,
    zIndex: 0,
    top: 0,

    display: "block",

    width: "28.4vw",
    height: "63.61vw",
    "@media (min-width:1440px)": {
      width: 409,
      height: 916,
    },
    "@media (max-width:767px)": {
      width: "28.74vw",
      height: "87.67vw",

      top: "50%",
      transform: "translateY(-50%)",
    },
  },
  logo: {
    width: "21.66vw",
    height: "12.5vw",
    "@media (min-width: 1440px)": {
      width: 312,
      height: 180,
    },
    "@media (max-width: 767px)": {
      width: "42.71vw",
      height: "24.64vw",
    },
  },
  title: {
    textAlign: "center",
    fontFamily: "Krona One",
    lineHeight: 1.5,
    textTransform: "uppercase",

    marginTop: "1.38vw",
    fontSize: "5vw",
    "@media (min-width:1440px)": {
      marginTop: 20,
      fontSize: 72,
    },
    "@media (max-width:767px)": {
      marginTop: "4.1vw",
      fontSize: "8.21vw",
    },

    "& *": {
      margin: 0,
    },

    "& span": {
      color: "#00FF85",
    },
  },
  signature: {
    textTransform: "uppercase",
    lineHeight: 1.27,

    marginTop: "4.1vw",
    fontSize: "4.1vw",

    "& *": {
      margin: 0,
    },
  },
  description: {
    textAlign: "center",
    opacity: 0.6,
    lineHeight: 1.27,

    marginTop: "1.38vw",
    fontSize: "0.97vw",
    "@media (min-width:1440px)": {
      marginTop: 20,
      fontSize: 14,
    },
    "@media (max-width:767px)": {
      marginTop: "4.1vw",
      fontSize: "3.49vw",
    },

    "& *": {
      margin: 0,
    },

    "& p + p": {
      marginTop: "0.41vw",
      "@media (min-width: 1440px)": {
        marginTop: 6,
      },
      "@media (max-width:767px)": {
        marginTop: "2.05vw",
      },
    },
  },
  ces__link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",

    marginTop: "1.38vw",
    "@media (min-width:1440px)": {
      marginTop: 20,
    },
    "@media (max-width:767px)": {
      marginTop: "4.1vw",
    },
  },
  ces__link__text: {
    lineHeight: 1.27,

    fontSize: "0.97vw",
    "@media (min-width:1440px)": {
      fontSize: 14,
    },
    "@media (max-width:767px)": {
      fontSize: "2.87vw",
    },
  },
  ces__link__logo: {
    display: "flex",

    marginLeft: "0.41vw",
    width: "0.97vw",
    height: "0.97vw",
    "@media (min-width:1440px)": {
      marginLeft: 6,
      width: 14,
      height: 14,
    },
    "@media (max-width:767px)": {
      marginLeft: "1.23vw",
      width: "2.87vw",
      height: "2.87vw",
    },
  },
  text_under_social: {
    textAlign: "center",
    margin: `0 auto`,

    marginTop: "2.91vw",
    fontSize: "1.18vw",
    "@media (min-width:1440px)": {
      marginTop: 42,
      fontSize: 17,
    },
    "@media (max-width:767px)": {
      marginTop: "8.62vw",
      fontSize: "4.51vw",
    },

    "& *": {
      margin: 0,
    },
  },
  social_networks: {
    display: "flex",

    marginTop: "20px",
    "@media (max-width: 1024px)": {
      marginTop: "2.5vw",
    },
    "@media (max-width: 767px)": {
      marginTop: "4vw",
    },
  },
  social_networks__item: {
    display: "block",
    marginRight: "30px",
    textDecoration: "none",
    cursor: "pointer",

    transition: ".3s all ease",
    "-webkit-transition": ".3s all ease",

    "@media (max-width:1024px)": {
      marginRight: "2vw",
      "&:last-child": {
        marginRight: 0,
      },
    },
    "@media (max-width:767px)": {
      marginRight: "2.5vw",
      "&:last-child": {
        marginRight: 0,
      },
    },

    "&:hover": {
      transform: "scale(1.1)",
      "-webkit-transform": "scale(1.1)",
    },

    "&:last-child": {
      marginRight: 0,
    },
  },
  social_networks__img: {
    height: "auto",

    width: "74px",
    "@media (max-width:1024px)": {
      width: "6.5vw",
    },
    "@media (max-width:767px)": {
      width: "13vw",
    },
  },
  form_wrapper: {
    marginTop: "2.77vw",
    "@media (min-width:1440px)": {
      marginTop: 40,
    },
    "@media (max-width:767px)": {
      marginTop: "8.21vw",
    },
  },
}))

export const Ces = ({ withImage }) => {
  const classes = useStyle()
  const data = useStaticQuery(graphql`
    {
      prismicLandingContent {
        data {
          ces_image_left {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          ces_image_right {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          ces_map {
            alt
            localFile {
              publicURL
            }
          }
          ces_icon {
            alt
            localFile {
              publicURL
            }
          }
          ces_main_title {
            text
            html
          }
          ces_first_screen_signature {
            text
            html
          }
          ces_main_description {
            text
            html
          }
          ces_link
          ces_link_content
          ces_text_under_social {
            text
            html
          }
          ces_social_networks {
            link
            icon {
              alt
              localFile {
                publicURL
              }
            }
          }
          ces_placeholder_input
          ces_content_button
        }
      }
    }
  `)

  const title = data.prismicLandingContent.data.ces_main_title
  const description = data.prismicLandingContent.data.ces_main_description
  const text_under_social =
    data.prismicLandingContent.data.ces_text_under_social
  const signature = data.prismicLandingContent.data.ces_first_screen_signature


  const mobile = useMediaQuery("(max-width: 767px)")

  return (
    <div className={classes.wrapper}>
      {withImage ? (
        <div className={classes.wrapper_images}>
          <GatsbyImage
            image={
              data.prismicLandingContent.data.ces_image_left.localFile
                .childImageSharp.gatsbyImageData
            }
            alt={
              data.prismicLandingContent.data.ces_image_left.alt ?? "image left"
            }
            className={classes.image_left}
            imgStyle={{ objectFit: "contain", width: "100%", height: "100%" }}
          />

          <GatsbyImage
            image={
              data.prismicLandingContent.data.ces_image_right.localFile
                .childImageSharp.gatsbyImageData
            }
            alt={
              data.prismicLandingContent.data.ces_image_left.alt ??
              "image right"
            }
            className={classes.image_right}
            imgStyle={{ objectFit: "contain", width: "100%", height: "100%" }}
          />
        </div>
      ) : null}

      <img
        src={data.prismicLandingContent.data.ces_icon.localFile.publicURL}
        alt={data.prismicLandingContent.data.ces_icon.alt ?? "ces"}
        width={312}
        height={180}
        className={classes.logo}
      />

      <Typography className={classes.title} component="div">
        <div
          dangerouslySetInnerHTML={{
            __html: title.html.includes("<pre>") ? title.text : title.html,
          }}
        />
      </Typography>

      {mobile ? (
        <Typography className={classes.signature} component="div">
          <div
            dangerouslySetInnerHTML={{
              __html: signature.html.includes("<pre>")
                ? signature.text
                : signature.html,
            }}
          />
        </Typography>
      ) : null}

      <Typography className={classes.description} component="div">
        <div
          dangerouslySetInnerHTML={{
            __html: description.html.includes("<pre>")
              ? description.text
              : description.html,
          }}
        />
      </Typography>

      <a
        href={data.prismicLandingContent.data.ces_map.localFile.publicURL}
        target="_blank"
        className={classes.ces__link}
      >
        <Typography className={classes.ces__link__text} component="span">
          {data.prismicLandingContent.data.ces_link_content}
        </Typography>

        <span className={classes.ces__link__logo}>
          <ArrowRight />
        </span>
      </a>

      <Typography className={classes.text_under_social} component="div">
        <div
          dangerouslySetInnerHTML={{
            __html: text_under_social.html.includes("<pre>")
              ? text_under_social.text
              : text_under_social.html,
          }}
        />
      </Typography>

      <div className={classes.social_networks}>
        {data.prismicLandingContent.data.ces_social_networks.map(
          (network, i) => (
            <a
              href={network.link}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.social_networks__item}
            >
              <img
                src={network.icon.localFile.publicURL}
                width="74"
                height="66"
                className={classes.social_networks__img}
                alt={network.icon.alt}
              />
            </a>
          )
        )}
      </div>

      <div className={classes.form_wrapper}>
        <FormCard />
      </div>
    </div>
  )
}
