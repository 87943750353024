import React from 'react'
import { makeStyles } from '@material-ui/core'
import Point from './point'
import ArrowLeft from '../../../../../static/svg/arrowLeft.svg'
import ArrowRight from '../../../../../static/svg/arrowRight.svg'

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 676,
        overflow:'hidden',
        display: 'flex',
        flexDirection: 'column',
        '@media (max-width:1024px)': {
            maxWidth: '99vw',
            margin: '0 auto',
        },
    },
    gradientContainer: {
        overflowY: 'hidden',
        overflowX: 'hidden',
    },
    pointsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        overflow: 'hidden',
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        '&::-webkit-scrollbar': {
             display: 'none',
            },
        '@media (max-width:1024px)': {
        overflow: 'scroll',
        },
        "&:after": {
            pointerEvents: 'none',
            content: '""',
            display: "block",
            position: "absolute",
            marginTop: '30px',
            marginLeft: '20px',
            height: "340px",
            width: "676px",
            background: 'linear-gradient(90deg, rgba(13,13,13,0) 50%, rgba(13,13,13,1) 100%)',
            '@media (max-width:1440px)': {
                visibility:' hidden',
                display: 'none',
                },
            '@media (max-width:1024px)': {
                visibility:' hidden',
                display: 'none',
                },
    },
},
    nav: {
       display: 'flex',
       marginLeft: 206,
       marginTop: 25,
       '@media (max-width:1024px)': {
        visibility:' hidden',
        display: 'none',
    },
    },
    arrow: {
        width: 30,
        padding: 0,
        cursor: 'pointer',
        background: 'none',
        outline: 'none',
        border: 'none',
        marginRight: 30,
        '&:last-child': {
            marginRight: 0,
        },
    },
}))

export default function PointContainer(props) {

    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div className={classes.gradientContainer}>
                <div className={classes.pointsContainer}>
                    {props.blocks.items.map((content, index) => (
                    <Point
                    inner={content}
                    index={index}
                    key={"block_name_slider" + index}
                    arrow={props.arrow}
                    />
                    ))}
                </div>
            </div>
            <nav className={classes.nav} >
               <button aria-label="Arrow Left" onClick={() => props.clickLeftArrow()} onKeyPress={() => props.clickLeftArrow()} className={classes.arrow}> <ArrowLeft  />  </button> 
               <button aria-label="Arrow Right" onClick={() => props.clickRightArrow()} onKeyPress={() => props.clickLeftArrow()} className={classes.arrow}> <ArrowRight  /> </button> 
            </nav>
        </div>
        
    )
}