import React from 'react'
import { makeStyles, Typography } from "@material-ui/core"
import Slider from 'infinite-react-carousel'
import './carousel.css'

const useStyles = makeStyles(theme => ({
    root: {
        '@media (max-width:1024px)': {
            padding: '0vw 10vw',
        },
        '@media (max-width:767px)': {
            padding: '0vw 5vw',
        },
    },
    title: {
        textAlign: "center",
        fontFamily: "Krona One",
        lineHeight: 1.5,
        textTransform: "uppercase",
    
        fontSize: "5vw",
        "@media (min-width:1440px)": {
          fontSize: 38,
        },
        "@media (max-width: 1024px)": {},
        "@media (max-width:767px)": {
          marginLeft: 0,
          marginTop: "4.51vw",
          fontSize: "10.26vw",
        },
    
        "& *": {
          margin: 0,
        },
    
        "& span": {
          color: "#00FF85",
        },
      },
    image: {
        width: '100%',
        height: 300,
        objectFit: 'contain',
        cursor: 'grab',
        '&:active': {
            cursor: 'grabbing',
        },
        '@media (max-width:1024px)': {
            height: 452,
        },
        '@media (max-width:767px)': {
            height: 200,
        },
    },
}))


export default function ProductCarousel() {

    const classes = useStyles()

    const IsMobile = typeof window !== 'undefined' && window.matchMedia("(max-width: 767px)").matches
    const IsPad = typeof window !== 'undefined' && window.matchMedia("(max-width: 1024px)").matches
    const IsSmall = typeof window !== 'undefined' && window.matchMedia("(max-width: 1424px)").matches

    const Carousel = () => {
        switch(IsMobile) {
            case true:
                return  (
                    <Slider { ...settingsMobile }>
                         <a href="https://techcrunch.com/2022/01/05/that-is-a-lot-of-power-you-guys/" target="_blank">
                            <picture className={classes.image} key="1">
                                <img srcSet="https://images.prismic.io/pwrboard/2545973b-8df4-4091-87c0-b2235ecd70b7_Techcrunch-grey-min.png?auto=compress,format" className={classes.image} />
                            </picture>
                        </a>
                        <a href="https://www.inputmag.com/tech/pwrboard-modular-battery-system-ces-2022" target="_blank">
                            <picture className={classes.image} key="2">
                                <img srcSet="https://images.prismic.io/pwrboard/3d0d38a7-f35e-4c4a-a233-abd2982a7f4b_input-grey-min.png?auto=compress,format" className={classes.image} />
                            </picture>
                        </a>
                        <a href="https://gizmodo.com/ditch-your-tangle-of-cables-and-chargers-for-the-lego-l-1848295030" target="_blank">
                            <picture className={classes.image} key="3">
                                <img srcSet="https://images.prismic.io/pwrboard/46db6822-42d8-48ad-ade0-49fe89a09724_Gizmodo-grey-min.png?auto=compress,format" className={classes.image} />
                        </picture>
                        </a>
                        <a href="https://www.cined.com/pwrboard-announced-a-camera-gear-charging-station/" target="_blank">
                            <picture className={classes.image} key="4">
                                <img srcSet="https://images.prismic.io/pwrboard/2fba839a-2d6a-469e-8fba-a3f6088d80f9_cined_logo_sm-grey-min.png?auto=compress,format" className={classes.image} />
                        </picture>
                        </a>
                        <a href="https://www.dpreview.com/news/7815400123/pwrboard-is-a-new-modular-way-to-keep-all-your-chargers-together-at-home-or-on-the-go" target="_blank">
                            <picture className={classes.image} key="5">
                                <img srcSet="https://images.prismic.io/pwrboard/ee95d628-3975-4acf-a139-d3c469c55ddc_dpreview-grey-min.png?auto=compress,format" className={classes.image} />
                            </picture>
                        </a>
                        <a href="https://www.divephotoguide.com/underwater-photography-scuba-ocean-news/pwrboard-modular-charging-station-ces-2022/" target="_blank">
                            <picture className={classes.image} key="6">
                                <img srcSet="https://images.prismic.io/pwrboard/0033ddb0-9dd2-4fb5-a80f-e5c3315462f5_divephotoguide-tr-grey-min.png?auto=compress,format" className={classes.image} />
                            </picture>
                        </a>

                    </Slider>
                )
            
            default:
                return PadCarousel(IsPad)
        }
    }

    const PadCarousel = () => {
        switch(IsPad) {
            case true:
                return  (
                    <Slider { ...settingsPad }>
                        <a href="https://techcrunch.com/2022/01/05/that-is-a-lot-of-power-you-guys/" target="_blank">
                            <picture className={classes.image} key="1">
                                <img srcSet="https://images.prismic.io/pwrboard/2545973b-8df4-4091-87c0-b2235ecd70b7_Techcrunch-grey-min.png?auto=compress,format" width="790" height="452" className={classes.image} />
                            </picture>
                        </a>
                        <a href="https://www.inputmag.com/tech/pwrboard-modular-battery-system-ces-2022" target="_blank">
                            <picture className={classes.image} key="2">
                                <img srcSet="https://images.prismic.io/pwrboard/3d0d38a7-f35e-4c4a-a233-abd2982a7f4b_input-grey-min.png?auto=compress,format" width="790" height="452" className={classes.image} />
                            </picture>
                        </a>
                        <a href="https://gizmodo.com/ditch-your-tangle-of-cables-and-chargers-for-the-lego-l-1848295030" target="_blank">
                            <picture className={classes.image} key="3">
                                <img srcSet="https://images.prismic.io/pwrboard/46db6822-42d8-48ad-ade0-49fe89a09724_Gizmodo-grey-min.png?auto=compress,format" width="790" height="452" className={classes.image} />
                            </picture>
                        </a>
                        <a href="https://www.dpreview.com/news/7815400123/pwrboard-is-a-new-modular-way-to-keep-all-your-chargers-together-at-home-or-on-the-go" target="_blank">
                            <picture className={classes.image} key="4">
                                <img srcSet="https://images.prismic.io/pwrboard/ee95d628-3975-4acf-a139-d3c469c55ddc_dpreview-grey-min.png?auto=compress,format" width="790" height="452" className={classes.image} />
                            </picture>
                        </a>
                        <a href="https://www.divephotoguide.com/underwater-photography-scuba-ocean-news/pwrboard-modular-charging-station-ces-2022/" target="_blank">
                            <picture className={classes.image} key="5">
                                <img srcSet="https://images.prismic.io/pwrboard/0033ddb0-9dd2-4fb5-a80f-e5c3315462f5_divephotoguide-tr-grey-min.png?auto=compress,format" width="790" height="452" className={classes.image} />
                            </picture>
                        </a>
                        <a href="https://www.cined.com/pwrboard-announced-a-camera-gear-charging-station/" target="_blank">
                            <picture className={classes.image} key="6">
                                <img srcSet="https://images.prismic.io/pwrboard/2fba839a-2d6a-469e-8fba-a3f6088d80f9_cined_logo_sm-grey-min.png?auto=compress,format" width="790" height="452" className={classes.image} />
                            </picture>
                        </a>
                    </Slider>
                )
            
            default:
                return SmallCarousel(IsSmall)
        }
    }

    const SmallCarousel = () => {
        switch(IsSmall) {
            case true:
                return (
                    <Slider { ...settingsSmall }>
                        <a href="https://techcrunch.com/2022/01/05/that-is-a-lot-of-power-you-guys/" target="_blank">
                            <picture className={classes.image} key="1">
                                <img srcSet="https://images.prismic.io/pwrboard/2545973b-8df4-4091-87c0-b2235ecd70b7_Techcrunch-grey-min.png?auto=compress,format" className={classes.image} />
                            </picture>
                        </a>
                        <a href="https://www.inputmag.com/tech/pwrboard-modular-battery-system-ces-2022" target="_blank">
                            <picture className={classes.image} key="2">
                                <img srcSet="https://images.prismic.io/pwrboard/3d0d38a7-f35e-4c4a-a233-abd2982a7f4b_input-grey-min.png?auto=compress,format" className={classes.image} />
                            </picture>
                        </a>
                        <a href="https://gizmodo.com/ditch-your-tangle-of-cables-and-chargers-for-the-lego-l-1848295030" target="_blank">
                            <picture className={classes.image} key="3">
                                <img srcSet="https://images.prismic.io/pwrboard/46db6822-42d8-48ad-ade0-49fe89a09724_Gizmodo-grey-min.png?auto=compress,format" className={classes.image} />
                            </picture>
                        </a>
                        <a href="https://www.dpreview.com/news/7815400123/pwrboard-is-a-new-modular-way-to-keep-all-your-chargers-together-at-home-or-on-the-go" target="_blank">
                            <picture className={classes.image} key="4">
                                <img srcSet="https://images.prismic.io/pwrboard/ee95d628-3975-4acf-a139-d3c469c55ddc_dpreview-grey-min.png?auto=compress,format" className={classes.image} />
                            </picture>
                        </a>
                        <a href="https://www.divephotoguide.com/underwater-photography-scuba-ocean-news/pwrboard-modular-charging-station-ces-2022/" target="_blank">
                            <picture className={classes.image} key="5">
                                <img srcSet="https://images.prismic.io/pwrboard/0033ddb0-9dd2-4fb5-a80f-e5c3315462f5_divephotoguide-tr-grey-min.png?auto=compress,format" className={classes.image} />
                            </picture>
                        </a>
                        <a href="https://www.cined.com/pwrboard-announced-a-camera-gear-charging-station/" target="_blank">
                            <picture className={classes.image} key="6">
                                <img srcSet="https://images.prismic.io/pwrboard/2fba839a-2d6a-469e-8fba-a3f6088d80f9_cined_logo_sm-grey-min.png?auto=compress,format" className={classes.image} />
                            </picture>
                        </a>
                    </Slider>
                )
            
            default:
                return (
                    
                    <Slider { ...settings }>
                        <a href="https://techcrunch.com/2022/01/05/that-is-a-lot-of-power-you-guys/" target="_blank">
                            <picture className={classes.image} key="1">
                                <img srcSet="https://images.prismic.io/pwrboard/2545973b-8df4-4091-87c0-b2235ecd70b7_Techcrunch-grey-min.png?auto=compress,format" className={classes.image} />
                            </picture>
                        </a>
                        <a href="https://gizmodo.com/ditch-your-tangle-of-cables-and-chargers-for-the-lego-l-1848295030" target="_blank">
                            <picture className={classes.image} key="2">
                                <img srcSet="https://images.prismic.io/pwrboard/46db6822-42d8-48ad-ade0-49fe89a09724_Gizmodo-grey-min.png?auto=compress,format" className={classes.image} />
                            </picture>
                        </a>
                        <a href="https://www.divephotoguide.com/underwater-photography-scuba-ocean-news/pwrboard-modular-charging-station-ces-2022/" target="_blank">
                            <picture className={classes.image} key="3">
                                <img srcSet="https://images.prismic.io/pwrboard/0033ddb0-9dd2-4fb5-a80f-e5c3315462f5_divephotoguide-tr-grey-min.png?auto=compress,format" className={classes.image} />
                            </picture>
                        </a>
                        <a href="https://www.dpreview.com/news/7815400123/pwrboard-is-a-new-modular-way-to-keep-all-your-chargers-together-at-home-or-on-the-go" target="_blank">
                            <picture className={classes.image} key="4">
                                <img srcSet="https://images.prismic.io/pwrboard/ee95d628-3975-4acf-a139-d3c469c55ddc_dpreview-grey-min.png?auto=compress,format" className={classes.image} />
                            </picture>
                        </a>
                        <a href="https://www.cined.com/pwrboard-announced-a-camera-gear-charging-station/" target="_blank">
                            <picture className={classes.image} key="5">
                                <img srcSet="https://images.prismic.io/pwrboard/2fba839a-2d6a-469e-8fba-a3f6088d80f9_cined_logo_sm-grey-min.png?auto=compress,format" className={classes.image} />
                            </picture>
                        </a>
                        <a href="https://www.inputmag.com/tech/pwrboard-modular-battery-system-ces-2022" target="_blank">
                            <picture className={classes.image} key="6">
                                <img srcSet="https://images.prismic.io/pwrboard/3d0d38a7-f35e-4c4a-a233-abd2982a7f4b_input-grey-min.png?auto=compress,format" className={classes.image} />
                            </picture>
                        </a>
                    </Slider>
                )
        }
    }

    const settingsMobile =  {
        arrows: false,
        centerMode: false,
        dots: true,
        adaptiveHeight: false,
        arrowsBlock: false,
    }

    const settingsPad =  {
        arrows: false,
        centerMode: false,
        dots: true,
        adaptiveHeight: false,
        arrowsBlock: false,
    }

    const settingsSmall =  {
        arrows: false,
        centerMode: true,
        dots: true,
        centerPadding: 90,
        adaptiveHeight: false,
        arrowsBlock: false,
    }

    const settings =  {
        arrows: false,
        centerMode: true,
        dots: true,
        centerPadding: 560,
        adaptiveHeight: false,
        arrowsBlock: false,
    }

    return (
        <div className={classes.root}>
        <>
          <div className={classes.header}>
            <Typography className={classes.title} component="div">
              <div
                dangerouslySetInnerHTML={{
                  __html: ("Featured in"),
                }}
              />
            </Typography>
          </div>
        </>
            {Carousel(IsMobile)}
        </div>
    )
}
